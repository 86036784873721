// FontSizeContext.js
import React, { createContext, useContext, useState } from 'react';

const FontSizeContext = createContext();

export const FontSizeProvider = ({ children }) => {
  const defaultFontSize = 14;
  const [fontSize, setFontSize] = useState(defaultFontSize);

  const increaseFontSize = () => {
    setFontSize((prevSize) => Math.min(prevSize + 1, 16))
  };

  const decreaseFontSize = () => {
    setFontSize((prevSize) => Math.max(prevSize - 1, defaultFontSize - 2));
  };

  const resetFontSize = () => {
    setFontSize(defaultFontSize); 
  };

  return (
    <FontSizeContext.Provider value={{ fontSize, increaseFontSize, decreaseFontSize, resetFontSize }}>
      {children}
    </FontSizeContext.Provider>
  );
};

export const useFontSize = () => {
  return useContext(FontSizeContext);
};
