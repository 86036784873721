import { Link } from "react-router-dom";

const UnderDevelopmentContent = () => {
  return (
    <div id="rs-page-error" className="rs-page-error">
      <div className="error-text">
        <h3
          className="error-message"
          title="Page Not Found"
          style={{ marginTop: "30px" }}
        >
          Faculty Development Centre is under development.
        </h3>

        <Link className="readon orange-btn" to="/" title="HOME">
          Back to Homepage
        </Link>
      </div>
    </div>
  );
};

export default UnderDevelopmentContent;
