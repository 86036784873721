import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { getMenu, getPageTitle } from "../../../../utils/getDomainData";
import { fetchData, imageBaseURL } from "../../../../utils/fetchData";
import { JNTUK_API_Urls } from "../../../../utils/api.config";
import { BlocksRenderer } from "../../../../components/BlockRenderer";
import profIcon from "../../../../assets/img/profile-icon.png";
const SDIC_Mentors_Investors = () => {
  const [data, setData] = useState([]);
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  useEffect(() => {
    fetchData(
      `${JNTUK_API_Urls["SDIC-Team"]}&filters[Category][$contains]=Investors`,
      setData
    );
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Investors – {getPageTitle(currentDomain)}</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <div className="pageTopOffset">
        <SiteBreadcrumb
          pageTitle="Investors"
          pageName="Investors"
          breadcrumbsClass="breadcrumb-bg"
        />
        {getMenu(currentDomain)}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg md-mb-30">
                <div className="content-wrapper">
                  <div className="content-section">
                    <h3 title="Sponsored Gold Medals">Investors</h3>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        display: "flex", 
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {data?.data?.map((obj) => (
                        <div
                          key={obj.id}
                          style={{
                            marginTop: "10px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            title="Img"
                            alt="Img"
                            src={
                              obj?.attributes?.Profile?.data
                                ? imageBaseURL +
                                  obj?.attributes?.Profile?.data?.attributes
                                    ?.url
                                : profIcon
                            }
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              marginBottom: "20px",
                            }}
                          />
                          <BlocksRenderer
                            content={obj?.attributes?.Details || []}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup secondaryColor" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SDIC_Mentors_Investors;
