import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Get_JNTUA_PDF_Links } from "../../../utils/fetchData";

const MenuItems = (props) => {
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);
  const location = useLocation();
  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };
  const subDomain = (url) => {
    // const userConfirmed = window.confirm(
    //   "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
    // );

    // if (userConfirmed) {
    window.open(url, "_blank");
    // } else {
    // }
  };
  return (
    <React.Fragment>
      <li className="rs-mega-menu menu-item-has-children submenuLeftAligned">
        <Link to="#">About</Link>
        <ul className="mega-menu half-column">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/about">Genesis- JNTUA</Link>
                </li>
                <li>
                  <Link to="/vision-mission">Vision & Mission</Link>
                </li>
                <li>
                  <Link to="/university-goals">University Goals</Link>
                </li>
                <li>
                  <Link to="/our-faculty">Our Faculty</Link>
                </li>
                <li>
                  <Link to="/jntua-policies">JNTUA Policies</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/honoris-causa">Honoris Causa</Link>
                </li>
                <li>
                  <Link to="/governing-hierarchy">Governing Hierarchy</Link>
                </li>
                <li>
                  <Link to="/gallery/univ-gallery">JNTUA Gallery</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/tie-ups-mous">Tie Ups & MOU’s</Link>
                </li>
                <li>
                  <Link to="/e-magazines">e-Magazines</Link>
                </li>
                <li>
                  <a
                    href={
                      "https://www.youtube.com/channel/UCPa6B2sY8Zz13i1mOTyIBvA/videos"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    75 Years Celebrations of JNTUACEA
                  </a>
                </li>
                <li>
                  <Link to="/university-song">JNTUA Anthem</Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                  <ul>
                    <li>
                      <Link to="/contact-us/university-officials">
                        Contact Details of University Officials
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us/examination-branch">
                        Examination Branch
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us/engineering-cell">
                        Engineering Cell
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us/co-ordinators">Co-Ordinators</Link>
                    </li>
                    <li>
                      <Link to="/convocations">Convocations</Link>
                    </li>
                    <li>
                      <Link to="/contact-us/sports-council">
                        Sports Council
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us/pro-peshi">PRO / Peshi</Link>
                    </li>
                    <li>
                      <Link to="/contact-us/administrative-office-staff">
                        Administrative Office Staff
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/location-map">Location Map</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#">Administration</Link>
        <ul className="mega-menu half-column">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/profile/chancellor">Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/profile/rector">Rector</Link>
                </li>
                <li>
                  <Link to="/profile/registrar">Registrar</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/executive-council">Executive Council</Link>
                </li>
                <li>
                  <Link to="/directorates">
                    University Administration / Directorate’s
                  </Link>
                </li>
                <li>
                  <Link to="/former-vice-chancellors">
                    Former Vice Chancellors
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/directorates">Directorates</Link>
                  <ul>
                    <li>
                      <Link to="/profile/academics-and-planning-dap">
                        Academic & Planning
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-academic-audit">
                        Academic Audit
                      </Link>
                    </li>

                    <li>
                      <Link to="/profile/admissions-da">Admissions</Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-evaluation-de">
                        Director of Evaluation
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-research-and-development">
                        Research and Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-industrial-relations-placements">
                        Industrial Relations & Placements
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-industrial-consultancy-services">
                        Industrial Consultancy Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-foreign-affairs-alumni-matters">
                        Foreign Affairs & Alumni Matters
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-faculty-development-iqac">
                        Faculty Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/internal-quality-assurance-cell">
                        Internal Quality Assurance Cell
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/software-development-centre">
                        Software Development Centre
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/sponsored-research">
                        Sponsored Research
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/women-empowerment-cell">
                        Women Empowerment Cell
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-oil-technological-pharmaceutical-research-institute">
                        Oil Technological & Pharmaceutical Research Institute
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/school-of-management-studies">
                        School of Management Studies
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#" as="#">
          Academics
        </Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/methodology">Methodology</Link>
                </li>
                <li>
                  <Link to="/admissions">Admission</Link>
                </li>
                <li>
                  <Link to="/syllabus">Regulations & Syllabus</Link>
                </li>
                <li>
                  <Link to="/programmes-offered">Programmes Offered</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/under-graduate">Under Graudate</Link>
                  <ul>
                    <li>
                      <a href="/course/1">Bachelor of Technology</a>
                    </li>
                    <li>
                      <a href="/course/2">Bachelor of Pharmacy</a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/post-graduate">Post Graduate</Link>
                  <ul>
                    <li>
                      <a href="/course/5">Master of Technology</a>
                    </li>
                    <li>
                      <a href="/course/6">Master of Pharmacy</a>
                    </li>
                    <li>
                      <a href="/course/7">Master of Computer Applications</a>
                    </li>
                    <li>
                      <a href="/course/8">Master of Bussiness Administration</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/phd-courses">Phd Courses</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">EXAMINATIONS</Link>
                  <ul>
                    <li>
                      <Link to="/profile/director-of-evaluation-de">
                        Director of Evaluation
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/controller-of-examinations-pg">
                        Controller of Examinations
                      </Link>
                    </li>
                    <li>
                      <Link to="/addl-controller-of-examinations">
                        Addl. Controller of Examinations
                      </Link>
                    </li>
                    <li>
                      <Link to="/examination-branch-application-forms">
                        Downloadable Documents
                      </Link>
                    </li>
                    <li>
                      <a
                        href={"https://jntuaebranchpayment.in/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Examination Branch Online Applications
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://www.icredify.com/degreeverify/jntua"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Degree Verification
                      </a>
                    </li>
                    <li>
                      <Link to="/category/University-Examinations">
                        Exams Calendars
                      </Link>
                    </li>
                    <li>
                      <a
                        href={"https://jntuaresults.ac.in/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Results
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="#">Constituent Colleges</Link>
                  <ul>
                    <li>
                      <Link
                        onClick={() =>
                          externalLink("https://www.jntuacea.ac.in/")
                        }
                      >
                        JNTUA - Ananthapuramu
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          externalLink("https://www.jntuacep.ac.in/")
                        }
                      >
                        JNTUA - Pulivendula
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          externalLink("https://www.jntuacek.ac.in/")
                        }
                      >
                        JNTUA - Kalikiri
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          externalLink("http://www.jntua-otpri.ac.in/")
                        }
                      >
                        JNTUA - (OTPRI) - Ananthapuramu
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/affiliated-colleges">AFFILIATED COLLEGES</Link>
                  <ul>
                    <li>
                      <Link to="/affiliated-colleges-anantapur">
                        Anantapur District
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-chittoor">
                        Chittoor District
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-y-s-r">
                        Y.S.R District
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-kurnool">
                        Kurnool District
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-nellore">
                        Nellore District
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>

      <li className="rs-mega-menu menu-item-has-children submenuRightAligned">
        <Link to="#">Assessment & Accreditation</Link>
        <ul className="mega-menu half-column">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/iqac">IQAC</Link>
                  <ul>
                    <li>
                      <Link to="/iqac/about">About IQAC</Link>
                    </li>
                    <li>
                      <Link to="/iqac/functions">Functions of IQAC</Link>
                    </li>
                    <li>
                      <Link to="/iqac/benefits">Benefits of IQAC</Link>
                    </li>
                    <li>
                      <Link to="/iqac/important-initiatives">
                        Important Initiatives Started at JNTUA
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">NIRF</Link>
                  <ul>
                    <li>
                      <Link to="/jntua-nirf">JNTUA- NIRF</Link>
                    </li>
                    <li>
                      <Link to="/nirf-reports">NIRF Reports</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">AISHE</Link>
                  <ul>
                    <li>
                      <Link to="/jntua-aishe">JNTUA- AISHE</Link>
                    </li>
                    <li>
                      <Link to="/aishe-reports">AISHE Reports</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="#">NAAC</Link>
                  <ul>
                    <li>
                      <Link to="/naac/Extended-Profile-Metrics">
                        Extended Profile Metrics
                      </Link>
                      <ul className="ps-3">
                        <li>
                          <Link to="/naac/Criteria-1">Criteria 1</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-2">Criteria 2</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-3">Criteria 3</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-4">Criteria 4</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-5">Criteria 5</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-6">Criteria 6</Link>
                        </li>
                        <li>
                          <Link to="/naac/Criteria-7">Criteria 7</Link>
                        </li>
                        <li>
                          <Link to="/naac/Workshop-Seminars">
                            Workshop/Seminars
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        href={JNTUA_PDF_Links?.Self_Study_Report}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Self-Study Report (SSR)
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
