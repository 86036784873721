
const SingleTeam = (props) => {
  const { teamClass, Image, Title } = props;
  return (
      <div className={teamClass ? teamClass : "team-item"}>
        <img src={Image} alt={Title} title={Title} />
        <div className="content-part">
          <h4 className="name">{Title}</h4>
        </div>
      </div>
  );
};

export default SingleTeam;
