import React from "react";
import { Link } from "react-router-dom";
function convertToPath(inputString) {
  // Replace spaces with dashes and remove special characters
  const path = inputString
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");

  // Encode the resulting string
  const encodedPath = encodeURIComponent(path);

  return encodedPath;
}
const EventsShort = (props) => {
  const { eventsClass, dateboxClass, slNoClass, fullDate, title, titleClass } =
    props;
  return (
    <div className={eventsClass ? eventsClass : "events-short d-flex mb-5"}>
      <div className={slNoClass ? slNoClass : "d-none"}>{props.slNo}.</div>
      <div className={dateboxClass ? dateboxClass : "date-part bgc1"}>
        <div className="date">{fullDate}</div>
      </div>
      <div className="content-part">
        <p className={titleClass ? titleClass : "title mb-0"}>
          <Link
            to={{
              pathname: `/notification/${convertToPath(
                title?.attributes?.Title
              )}`,
              state: { notification: title },
            }}
          >
            {title?.attributes?.notification_department?.data?.attributes
              ?.Prefix
              ? title?.attributes?.notification_department?.data?.attributes
                  ?.Prefix + " - "
              : ""}
            {title?.attributes?.Title}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default EventsShort;
