import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";
import { Get_JNTUA_PDF_Links } from "../../../utils/fetchData";
import univBuilding1 from "../../../assets/img/univ-building/1.jpg";
import univBuilding2 from "../../../assets/img/univ-building/2.jpg";
import univBuilding3 from "../../../assets/img/univ-building/3.jpg";
import univBuilding4 from "../../../assets/img/univ-building/4.jpg";
import univBuilding5 from "../../../assets/img/univ-building/5.jpg";
import univBuilding6 from "../../../assets/img/univ-building/6.jpg";
import univBuilding7 from "../../../assets/img/univ-building/7.jpg";
import univBuilding8 from "../../../assets/img/univ-building/8.jpg";
import jntuaEmblemLogo from "../../../assets/img/logo/jntua-emblem-logo.png";
import jntualocation from "../../../assets/img/jntua-location.png";

const photos = [
  {
    src: univBuilding1,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding2,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding3,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding4,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding5,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding6,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding7,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding8,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
];

const Footer = (props) => {
  const { footerLogo, footerClass, footerTopClass } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUA Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };
  const subDomain = (url) => {
    // const userConfirmed = window.confirm(
    //   "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
    // );

    // if (userConfirmed) {
    window.open(url, "_blank");
    // } else {
    // }
  };
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-container-ts"></div>
      <div className="footer-center">
        <div className="container">
          <div className="pb-50">
            <div className="sec-title">
              <h2
                className="sub-title text-white text-center"
                title="Contact Us"
              >
                Contact Us
              </h2>
            </div>
            <div className="sec-title">
              <p className="desc big text-white text-center">
                Feel Free To Contact Us Any Time
              </p>
            </div>
          </div>
          <div className="row y-bottom">
            <div className="col-lg-4 text-center text-lg-start">
              <h3 title="Communication Address">Communication Address</h3>
              <h4 title="The Registrar">The Registrar</h4>
              <p> JNT University Anantapur,</p>
              <p>Ananthapuramu - 515002,</p>
              <p>Andhra Pradesh, India</p>

              <div className="footer-top-social">
                <div className="social_icon_container">
                  <ul className="social_icon">
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/people/Jntua-Anantapur/pfbid0BeujjbThmhJzG5q5T4mf5YwukvcwBeWicBh1t7Ct9q7kWhJH4Rz4SaZeqEeCDe14l/"
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a
                        href="https://twitter.com/jntuau"
                        target="_blank"
                        aria-label="Twitter"
                      >
                        <i className="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                    <li className="pinterest">
                      <a href="#" target="_blank" aria-label="Pinterest">
                        <i className="fa-brands fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/jntua_official/"
                        target="_blank"
                        aria-label="Instagram"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li className="google-plus">
                      <a href="#" target="_blank" aria-label="Google Plus">
                        <i className="fa-brands fa-google-plus-g"></i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://www.linkedin.com/school/jntua-college-of-engineering-anantapur/posts/?feedView=all"
                        target="_blank"
                        aria-label="Linkedin"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="youtube">
                      <a
                        href="https://youtube.com/channel/UC_OHiczVphbfnIEmFtzJKYQ"
                        target="_blank"
                        aria-label="Youtube"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 md-mt-30">
              <div className="row">
                <div className="col-6">
                  <div className="ctaButton">
                    <a href="mailto:Info@jntua.ac.in" className="icn">
                      <i className="fa-regular fa-envelope"></i>
                    </a>
                    <a href="mailto:Info@jntua.ac.in">
                      <strong>Email Us</strong>
                    </a>
                    <a href="mailto:Info@jntua.ac.in">
                      <span>Info@jntua.ac.in</span>
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="ctaButton">
                    <a href="tel:+91999999999" className="icn">
                      <i className="fa-regular fa-phone"></i>
                    </a>
                    <a href="tel:+91999999999">
                      <strong>Call Us</strong>
                    </a>
                    <a href="tel:+91999999999">
                      <span>0999999999</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row y-middle mt-50">
            <div className="col-lg-4 text-center">
              <img src={jntualocation} className="" />
            </div>
            <div className="col-lg-8 md-mt-20">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.077813705945!2d77.60589311461557!3d14.651524189770047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb14a555e1d626f%3A0x92d44831356941d1!2sJawaharlal+Nehru+Technological+University+Anantapur!5e0!3m2!1sen!2sus!4v1535200108942"
                width="100%"
                height="320"
                allowFullScreen=""
                aria-hidden="false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className={`footer-top ${footerTopClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="About JNTUA">
                About JNTUA
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/about">Genesis- JNTUA</Link>
                </li>
                <li>
                  <Link to="/profile/chancellor">Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/rector">Rector</Link>
                </li>
                <li>
                  <Link to="/profile/registrar">Registrar</Link>
                </li>
                <li>
                  <Link to="/executive-council">Executive Council</Link>
                </li>
                <li>
                  <Link to="/directorates">Directorates</Link>
                </li>
                <li>
                  <Link to="/governing-hierarchy">Governing Hierarchy</Link>
                </li>
                <li>
                  <Link to="/tie-ups-mous">Tie Ups & MOU’s</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="Academics">
                Academics
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/methodology">Methodology</Link>
                </li>
                <li>
                  <Link to="/admissions">Admissions</Link>
                </li>
                <li>
                  <Link to="/under-graduate">Under Graduate</Link>
                </li>
                <li>
                  <Link to="/post-graduate">Post Graduate</Link>
                </li>
                <li>
                  <Link to="/phd-courses">Ph.D</Link>
                </li>
                <li>
                  <Link to="/profile/controller-of-examinations-pg">
                    Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/courses-offered">Courses Offered</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="ADMINISTRATION">
                ADMINISTRATION
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/profile/chancellor">Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/rector">Rector</Link>
                </li>
                <li>
                  <Link to="/profile/registrar">Registrar</Link>
                </li>
                <li>
                  <Link to="/executive-council">Executive Council</Link>
                </li>
                <li>
                  <Link to="/directorates">
                    University Administration / Directorate’s
                  </Link>
                </li>
                <li>
                  <Link to="/governing-hierarchy">
                    University Governing Hierarchy
                  </Link>
                </li>
                <li>
                  <Link to="/vision-mission">Vision & Mission</Link>
                </li>
                <li>
                  <Link to="/university-goals">University Goals</Link>
                </li>
                <li>
                  <Link to="/university-song">University Song</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="EXAMINATIONS">
                EXAMINATIONS
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/profile/controller-of-examinations-pg">
                    Controller of Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/addl-controller-of-examinations">
                    Addl Controller of Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/examination-branch-application-forms">
                    Downloadable Documents
                  </Link>
                </li>
                <li>
                  <a
                    href={"https://jntuaebranchpayment.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Examination Branch Online Applications
                  </a>
                </li>
                <li>
                  <a
                    href={"https://www.icredify.com/degreeverify/jntua"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Degree Verification
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="DIRECTORATES">
                DIRECTORATES
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/profile/academics-and-planning-dap">
                    Academic & Planning
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-academic-audit">
                    Academic Audit
                  </Link>
                </li>
                <li>
                  <Link to="/admissions">Admissions</Link>
                </li>
                <li>
                  <Link to="/profile/director-of-evaluation-de">
                    Evaluation
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-research-and-development">
                    Research & Development
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-industrial-relations-placements">
                    Industrial Relations & Placements
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-industrial-consultancy-services">
                    Industrial Consultancy Services
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-foreign-affairs-alumni-matters">
                    Foreign Affairs & Alumni Matters
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-faculty-development-iqac">
                    Faculty Development
                  </Link>
                </li>
                <li>
                  <Link to="/profile/internal-quality-assurance-cell">
                    Internal Quality Assurance Cell
                  </Link>
                </li>
                <li>
                  <Link to="/profile/software-development-centre">
                    Software Development Centre
                  </Link>
                </li>
                <li>
                  <Link to="/profile/sponsored-research">
                    Sponsored Research
                  </Link>
                </li>
                <li>
                  <Link to="/profile/women-empowerment-cell">
                    Women Empowerment Cell
                  </Link>
                </li>

                <li>
                  <Link to="/profile/director-of-oil-technological-pharmaceutical-research-institute">
                    Oil Technological & Pharmaceutical Research Institute
                  </Link>
                </li>
                <li>
                  <Link to="/profile/school-of-management-studies">
                    School of Management Studies
                  </Link>
                </li>
              </ul>

              <h3
                className="widget-title mt-10"
                title="Internal Quality Assurance Cell (IQAC)"
              >
                Internal Quality Assurance Cell (IQAC)
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/dfd/committee">Committee</Link>
                </li>
                <li>
                  <Link to="/dfd/sss-minutes">SSS/Minutes</Link>
                </li>
                <li>
                  <Link to="/dfd/feedback">Feedback</Link>
                </li>
                <li>
                  <Link to="/dfd">AQAR</Link>
                </li>
                <li>
                  <Link to="/dfd">SSR</Link>
                </li>
                <li>
                  <Link to="/dfd/certificates">Certificates</Link>
                </li>
                <li>
                  <Link to="/dfd/guidelines">Guidelines</Link>
                </li>
                <li>
                  <Link to="/dfd/gallery">Gallery</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="GATE Online Classes">
                GATE Online Classes
              </h3>
              <ul className="site-map">
                <li>
                  <a
                    href={
                      "https://www.youtube.com/playlist?list=PLf5_cCdJr87bF9YVUULmwyvzlS5XI2wbl"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    ECE
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://www.youtube.com/playlist?list=PLxYlKnkJqcWG41XJvokTzhZpRGFhfbMDI"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    MECH
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://www.youtube.com/playlist?list=PLapBOXigUK2QnvL_gQuJSQQDIGJ5yfMrN"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    CSE
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="HAPPENINGS">
                HAPPENINGS
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/category/DAA-Notifications">
                    DAA Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/category/DAP-Notifications">
                    DAP Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/category/FAAM-Notifications">
                    FAAM Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/category/Faculty-Development">
                    Faculty Development Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/category/Latest-News">Latest News</Link>
                </li>
                <li>
                  <Link to="/category/Live-Scrolling">Scrolling</Link>
                </li>
                <li>
                  <Link to="/category/SDIC-Notifications">
                    SDIC Notifications
                  </Link>
                </li>
                <li>
                  <Link to="/category/Uncategorized">Uncategorized</Link>
                </li>
                <li>
                  <Link to="/category/University-Admissions">
                    University Admissions
                  </Link>
                </li>
                <li>
                  <Link to="/category/Anti-Ragging">
                    University Anti Ragging Cell
                  </Link>
                </li>
                <li>
                  <Link to="/category/Events">University Events</Link>
                </li>
                <li>
                  <Link to="/category/University-Examinations">
                    University Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/category/Gallery">University Gallery</Link>
                </li>
                <li>
                  <Link to="/category/Industrial-Consultancy-Services">
                    University Industrial Consultancy Services
                  </Link>
                </li>
                <li>
                  <Link to="/category/Industrial-Relations-&-Placements">
                    University Industrial Relations & Placements
                  </Link>
                </li>
                <li>
                  <Link to="/category/NSS-Cell">University NSS Cell</Link>
                </li>
                <li>
                  <Link to="/category/Research-&-Development">
                    University Research & Development
                  </Link>
                </li>
                <li>
                  <Link to="/category/University-Sports">
                    University Sports
                  </Link>
                </li>
                <li>
                  <Link to="/category/Tenders">University Tenders</Link>
                </li>
              </ul>

              <h3
                className="widget-title mt-10"
                title="JNTUA - CONSTITUENT UNITS"
              >
                JNTUA - CONSTITUENT UNITS
              </h3>
              <ul className="site-map">
                <li>
                  <a
                    href={"https://www.jntuacea.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUA CEA
                  </a>
                </li>
                <li>
                  <a
                    href={"https://jntuacep.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUA CEP
                  </a>
                </li>
                <li>
                  <a
                    href={"https://www.jntuacek.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUA CEK
                  </a>
                </li>
                <li>
                  <a
                    href={"https://jntuaotpri.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUA-OTPRI
                  </a>
                </li>
                <li>
                  <a
                    href={"https://jntuasms.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUA SMS
                  </a>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="Download">
                Download
              </h3>
              <ul className="site-map">
                <li>
                  <a
                    href={
                      JNTUA_PDF_Links?.Procedure_for_Organizing_Graduation_day
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Procedure for Organizing Graduation day
                  </a>
                </li>
                <li>
                  <Link to="/guidelines-for-donation">
                    Guidelines for Donation
                  </Link>
                </li>
                <li>
                  <Link to="/research-development">Research & Development</Link>
                </li>
                <li>
                  <Link to="/syllabus">Syllabus</Link>
                </li>
                <li>
                  <Link to="/annual-reports">Annual Reports</Link>
                </li>
                <li>
                  <Link to="/examination-branch-application-forms">
                    Examination Branch Application Forms
                  </Link>
                </li>
                <li>
                  <Link to="/affiliated-colleges">Affiliated Colleges</Link>
                </li>
                <li>
                  <Link to="/news-letters">News Letters</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
              <h3 className="widget-title" title="Other Links">
                Other Links
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/registered-ph-d-scholars">
                    Registered Ph.D Scholars
                  </Link>
                </li>
                <li>
                  <Link to="/rti-act">RTI-Act</Link>
                </li>
                <li>
                  <Link to="/category/Events">Events</Link>
                </li>
                <li>
                  <Link to="/category/NSS-Cell">NSS Cell</Link>
                </li>
                <li>
                  <Link to="/archive">Archive</Link>
                </li>
                <li>
                  <Link to="/category/Anti-Ragging">Anti Ragging</Link>
                </li>
                <li>
                  <Link to="/gallery/gallery">Gallery</Link>
                </li>
                <li>
                  <a
                    href={JNTUA_PDF_Links.Publishers_Pdf_File}
                    target="_blank"
                    rel="noreferrer"
                  >
                    List of Suggested Books Indian Authors Publishers
                  </a>
                </li>
                <li>
                  <a
                    href={JNTUA_PDF_Links.Footer_EMagazine}
                    target="_blank"
                    rel="noreferrer"
                  >
                    e-Magazine September 2023 Issue
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://www.youtube.com/channel/UCPa6B2sY8Zz13i1mOTyIBvA/videos"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    75 Years Celebrations of JNTUACEA- Videos
                  </a>
                </li>
                <li>
                  <Link to="/university-song">JNTUA Anthem</Link>
                </li>
                <li>
                  <a
                    href={
                      "https://cets.apsche.ap.gov.in/APSCHE/APSCHEHome.aspx"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    AP EAPCET 2022
                  </a>
                </li>
                <li>
                  <a
                    href={JNTUA_PDF_Links.MoRTH_Recruitment}
                    target="_blank"
                    rel="noreferrer"
                  >
                    MoRTH Recruitment
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://cets.apsche.ap.gov.in/APPGCET2023/UI/HomePages/Home"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    AP PGCET 2023
                  </a>
                </li>
                <li>
                  <Link to="">Azadi ka Amrit Mahotsav</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="Postal Contacts">
                Postal Contacts
              </h3>
              <ul className="site-map">
                <li>
                  The Registrar,<br></br>
                  JNT University Anantapur,<br></br>
                  Ananthapuramu - 515002,<br></br>
                  Andhra Pradesh, India.
                </li>
              </ul>

              <img src={jntuaEmblemLogo} className="mt-50" />
            </div>
          </div>
        </div>
      </div>

      <FooterBottom />
    </footer>
  );
};

export default Footer;
