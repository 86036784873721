import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { getMenu, getPageTitle } from "../../../../utils/getDomainData";
import { SCOEMenu } from "../../../../components/Common/SidebarMenu";
import { fetchData, imageBaseURL } from "../../../../utils/fetchData";
import { JNTUK_API_Urls } from "../../../../utils/api.config";
import { BlocksRenderer } from "../../../../components/BlockRenderer";
import profIcon from "../../../../assets/img/profile-icon.png";
import GalleryPart from "../../../galleryAll/GalleryPart";
import pdf from "../../../../assets/img/pdf.png";

const SDIC_SCOE = () => {
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  const { type } = useParams();
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setData([]);
    setTableData([]);
    if (!type || type === "equipments") {
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Equipments-Details"], setData);
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Equipments"], setTableData);
    } else if (type === "employees")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Onboard-Employees"], setData);
    else if (type === "laboratories")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Laboratories"], setTableData);
    else if (type === "brochures")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Brochures"], setTableData);
    else if (type === "registration")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Registration"], setData);
    else if (type === "photo-gallery")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Photo-Gallery"], setData);
    else if (type === "video-gallery")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-Video-Gallery"], setData);
    else if (type === "media")
      fetchData(JNTUK_API_Urls["SDIC-SCOE-In-Media"], setData);
    else setData([]);
  }, [type]);
  const getTitle = (type) => {
    if (!type || type === "equipments") return "List of Equipments";
    else if (type === "laboratories") return "List of Laboratories";
    else if (type === "employees") return "Onboard Working Employees";
    else if (type === "registration") return "Registration";
    else if (type === "photo-gallery") return "Photo Gallery";
    else if (type === "video-gallery") return "Video Gallery";
    else if (type === "media") return "In Media";
    else if (type === "brochures") return "Brochures";
    else if (type === "candidates") return "List of Candidates trained";
    else return "";
  };
  const CandidatesPages = () => {
    return (
      <div style={{marginTop:"10px"}}>
        <ul>
        <Link to={"/sdic/computer-aided-design-cad"}> <li>CAD (Computer Aided Design)</li></Link>
        <Link to={"/sdic/mechatronics"}> <li>Mechatronics</li></Link>
        <Link to={"/sdic/automotive-body-painting"}> <li>Automotive Body Painting</li></Link>
        <Link to={"/sdic/robotics"}> <li>Robotics</li></Link>
        <Link to={"/sdic/electrical"}> <li>Electrical</li></Link>
        <Link to={"/sdic/process-instrumentation"}> <li>Process Instrumentation</li></Link>
        <Link to={"/sdic/cnc-programming"}> <li>CNC (Computerized Numeric Control) Programming</li></Link>
        <Link to={"/sdic/cnc-machines"}> <li>CNC (Computerized Numeric Control) Machining</li></Link>
        <Link to={"/sdic/robcad"}><li>RobCad</li></Link>
        <Link to={"/sdic/automation"}>  <li>Automation</li></Link>
        </ul>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Siemens Centre of Excellence – {getPageTitle(currentDomain)}
        </title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <div className="pageTopOffset">
        <SiteBreadcrumb
          pageTitle="Siemens Centre of Excellence"
          pageName="Siemens Centre of Excellence"
          breadcrumbsClass="breadcrumb-bg"
        />
        {getMenu(currentDomain)}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <SCOEMenu />
                </div>
              </div>
              <div className="col-xl">
                <div className="container">
                  <div className="row">
                    <div className="col-lg md-mb-30">
                      <div className="content-wrapper">
                        <div className="content-section">
                          <h3 title="Sponsored Gold Medals">
                            {getTitle(type)}
                          </h3>
                          <div style={{ marginTop: "10px" }}>
                            <BlocksRenderer
                              content={data?.data?.attributes?.Body || []}
                            />
                          </div>
                          {type === "laboratories" && (
                            <div>
                              <p>
                                <b>
                                  List of Laboratories in COE, Siemens center of
                                  Excellence
                                </b>
                              </p>
                            </div>
                          )}
                          {(!type ||
                            type === "equipments" ||
                            type === "laboratories" ||
                            type === "brochures") && (
                            <div className="overflow-x">
                              <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Download</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData?.data?.map((obj, index) => (
                                    <tr>
                                      <td>
                                        {(index + 1)
                                          .toString()
                                          .padStart(2, "0")}
                                        .
                                      </td>
                                      <td>{obj?.attributes?.Title}</td>
                                      <td align="center">
                                        {obj?.attributes?.File?.data?.attributes
                                          ?.url ? (
                                          <a
                                            href={
                                              imageBaseURL +
                                              obj?.attributes?.File?.data
                                                ?.attributes?.url
                                            }
                                            className="d-flex flex-column align-items-center"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              alt="PDF file"
                                              title="PDF file"
                                              src={pdf}
                                              className="mb-5"
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          </a>
                                        ) : (
                                          <img
                                            alt="PDF file"
                                            title="PDF file"
                                            src={pdf}
                                            className="mb-5"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              marginLeft: "10px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {type === "employees" && (
                            <div
                              className="overflow-x"
                              style={{ marginTop: "20px" }}
                            >
                              <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Photo</th>
                                    <th scope="col">Name & Designation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.data?.length > 0 &&
                                    data?.data?.map((obj, index) => (
                                      <tr>
                                        <td>
                                          {(index + 1)
                                            .toString()
                                            .padStart(2, "0")}
                                          .
                                        </td>
                                        <td>
                                          <img
                                            title="JNTUK Vice Chancellor's"
                                            alt="JNTUK Vice Chancellor's"
                                            style={{
                                              width: "250px",
                                              height: "250px",
                                            }}
                                            src={
                                              obj?.attributes?.Profile?.data
                                                ? imageBaseURL +
                                                  obj?.attributes?.Profile?.data
                                                    ?.attributes?.url
                                                : profIcon
                                            }
                                          />
                                        </td>
                                        <td>
                                          <BlocksRenderer
                                            content={
                                              obj?.attributes?.Details || []
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              <div
                                style={{
                                  marginTop: "20px",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              ></div>
                            </div>
                          )}
                          {type === "video-gallery" && (
                            <div
                              className="video-gallery"
                              style={{
                                display: "flex",
                                flexWrap: "wrap", // Allow items to wrap to the next row
                                gap: "20px", // Add some space between items
                                marginTop: "20px",
                                justifyContent: "space-between",
                              }}
                            >
                              {data?.data?.length > 0 &&
                                data?.data?.map((obj, index) => (
                                  <iframe
                                    key={index}
                                    width="490"
                                    height="315"
                                    src={obj?.attributes?.Link}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                ))}
                            </div>
                          )}
                          {(type === "photo-gallery" || type === "media") && (
                            <div
                              className="photo-gallery"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                marginTop: "20px",
                                justifyContent: "space-between",
                              }}
                            >
                              {data?.data?.attributes?.Images?.data?.length >
                                0 && (
                                <GalleryPart
                                  title={""}
                                  images={data?.data?.attributes?.Images || []}
                                />
                              )}
                            </div>
                          )}
                          {type === "candidates" && (
                            <div>
                              <p>
                                <b>
                                  List of Candidates trained in COE, Siemens
                                  center of Excellence
                                </b>
                                <CandidatesPages />
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup secondaryColor" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SDIC_SCOE;
