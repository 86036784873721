import React from "react";
import PropTypes from "prop-types";
import "./Pagination.css"; 

const Pagination = ({ currentPage, arrayLength, paginate }) => {
  const totalPages = Math.ceil(arrayLength);
  const maxPagesToShow = 5;

  let startPage = 1;
  let endPage = totalPages;
  if (totalPages > maxPagesToShow) {
    const halfPagesToShow = Math.floor(maxPagesToShow / 2);
    if (currentPage > halfPagesToShow) {
      startPage = currentPage - halfPagesToShow;
      endPage = currentPage + halfPagesToShow;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }
    } else {
      endPage = maxPagesToShow;
    }
  }

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      paginate(page);
    }
  };

  return (
    <div>
      <ul className="pagination">
        {currentPage > 1 && (
          <li className="page-item">
            <button onClick={() => handlePageClick(currentPage - 1)} className="page-link">
              Previous
            </button>
          </li>
        )}
        {startPage > 1 && (
          <li className="page-item">
            <button onClick={() => handlePageClick(1)} className="page-link">
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
          <li key={startPage + i} className={`page-item ${currentPage === startPage + i ? "active" : ""}`}>
            <button onClick={() => handlePageClick(startPage + i)} className="page-link">
              {startPage + i}
            </button>
          </li>
        ))}
        {endPage < totalPages - 1 && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}
        {endPage < totalPages && (
          <li className="page-item">
            <button onClick={() => handlePageClick(totalPages)} className="page-link">
              {totalPages}
            </button>
          </li>
        )}
        {currentPage < totalPages && (
          <li className="page-item">
            <button onClick={() => handlePageClick(currentPage + 1)} className="page-link">
              Next
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  arrayLength: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired
};

export default Pagination;
