import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "../pages/home";
import Mission from "../pages/mission";
import About from "../pages/about";
import Gallery from "../pages/gallery";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import IQAC from "../pages/iqac";
import SDD from "../pages/sdd";
import InstitutionalBody from "../pages/institutional-bodies";
import Profile from "../pages/profile";
import ExecutiveCouncil from "../pages/executive council";
import AcademicSenate from "../pages/departments/dap/academic senate";
import BoardOfStudies from "../pages/board of studies";
import GoverningHierarchy from "../pages/governing hierarchy";
import Methodology from "../pages/methodology";
import Admissions from "../pages/admissions";
import CircularAspects from "../pages/circular aspects";
import Teaching_Learning_Process from "../pages/teaching learning process";
import Student_Support_And_Progression from "../pages/student support and progression";
import Infrastructure_and_Learning_Resources from "../pages/infrastructure and learning resources";
import Convocations from "../pages/convocations";
import AutonomousColleges from "../pages/autonomous colleges";
import University_Examination_Fee_Structure from "../pages/University Examination Fee Structure";
import Affiliated_Colleges_East_Godavari from "../pages/affiliated colleges east godavari";
import Affiliated_Colleges_West_Godavari from "../pages/affiliated colleges west godavari";
import Affiliated_Colleges_Krishna from "../pages/affiliated colleges krishna";
import Affiliated_Colleges_Guntur from "../pages/affiliated colleges guntur";
import Affiliated_Colleges_Prakasam from "../pages/affiliated colleges prakasam";
import UniversitySong from "../pages/university song";
import Addl_Controller_of_Examinations from "../pages/addl controller of examinations";
import ExaminationUG from "../pages/ExaminationUG";
import ProgrammesOffered from "../pages/programmes offered";
import UnderGraduate from "../pages/under graduate";
import PostGraduate from "../pages/post graduate";
import Courses from "../pages/courses";
import SpecialOfficers from "../pages/SpecialOfficers";
import SpecialOfficersProfile from "../pages/SpecialOfficersProfile";
import NAACReports from "../pages/naac-reports";
import Notifications from "../pages/notifications";
import Directorates from "../pages/Directorates";
import Convocation from "../pages/Convocation";
import Location from "../pages/location";
import Terms from "../pages/terms";
import WebPolicy from "../pages/web-policy";
import Disclaimer from "../pages/disclaimer";
import NotificationDetails from "../pages/notificationDetails";
import JNTUKViceChancellor from "../pages/former vice chancellors";
import GalleryALL from "../pages/galleryAll";
import CampusBuildings from "../pages/campus-buildings";
import DownloadAppFormats from "../pages/download-app-formats";
import Vice_Chancellor_Message from "../pages/vice-chancellor-message";
import Faculty from "../pages/faculty";
import CommonHome from "../pages/departments/common pages/home";
import CommonContact from "../pages/departments/common pages/contact";
import CommonAboutDirectorate from "../pages/departments/common pages/aboutDirectorate";
import CommonNewsletter from "../pages/departments/common pages/newsletter";
import UniversityGoals from "../pages/university goals";
import TieUpsMOUs from "../pages/Tie Ups & MOU";
import HonorisCausa from "../pages/Honoris Causa";
import Affiliated_Colleges from "../pages/Affiliated Colleges";
import JNTUA_Contacts from "../pages/jntua contact us";
import PHD_Courses from "../pages/phd courses";
import Guidelines_for_Donation from "../pages/guidelines-for-donation";
import ResearchDevelopment from "../pages/research-and-development-download";
import JNTUANewsLetters from "../pages/jntua-news-letters";
import RegisteredPhDScholars from "../pages/registered-ph-d-scholars";
import EBAForms from "../pages/examination-branch-application-form";
import ArchiveFiles from "../pages/archive-files";
import JNTUA_Policies from "../pages/jntua-policies";
import E_Magazine from "../pages/e-magazine";
import AnnualReports from "../pages/annual-reports";
import NIRF_Reports from "../pages/nirf-reports";
import AISHE_Reports from "../pages/aishe-reports";
import JNTUA_NIRF from "../pages/jntua-nirf";
import JNTUA_AISHE from "../pages/jntua-aishe";
import CommonOthers from "../pages/departments/common pages/other";
import CommonDownloads from "../pages/departments/common pages/downloads";
import CommonGallery from "../pages/departments/common pages/gallery";
import CommonGalleryALL from "../pages/departments/common pages/galleryAll";
import CommonAboutUs from "../pages/departments/common pages/aboutUs";
import Regulations_Syllabus from "../pages/syllabus";
import Post_Graduate_Courses from "../pages/post-graduate-courses";
import Under_Graduate_Courses from "../pages/under-graduate-courses";
import ProgrammesOfferedList from "../pages/programmes offered list";
import Research_Programmes from "../pages/departments/common pages/other";
import News_Bulletin from "../pages/departments/dap/news bulletin";
import UG_Programmes from "../pages/departments/dap/ug-programmes";
import Regular_Courses from "../pages/departments/dap/regular-courses";
import Part_Time from "../pages/departments/dap/part-time";
import Dual_Degree_Programmes from "../pages/departments/dap/integrated-dual-degree-programmes";
import Mous_List from "../pages/departments/dap/mous-list";
import DIRP_Placements from "../pages/departments/dirp/placements";
import DWEC_Programmes from "../pages/departments/dwec/programmes";
import DWEC_Notifications from "../pages/departments/dwec/notifications";
import DWEC_Compliant from "../pages/departments/dwec/internal-compliant-committee";
import DFAAM_Notifications from "../pages/departments/dfaam/notifications";
import DFAAM_Sponsored_Gold_Medals from "../pages/departments/dfaam/sponsored-gold-medals";
import DSDC_People from "../pages/departments/dsdc";
import DFAAM_MOU from "../pages/departments/dfaam/mous";
import DFAAM_List_of_Foreign_Students from "../pages/departments/dfaam/list-of-foreign-students";
import DFAAM_Emratus from "../pages/departments/dfaam/emratus";
import DFAAM_12b_2f_Certificates from "../pages/departments/dfaam/12b-2f-certificates";
import DRD_PhD_Scholars_Admitted_Year_Wise from "../pages/departments/drd/ph-d-scholars-admitted";
import DRD_Regulations from "../pages/departments/drd/regulations";
import DRD_Downloads from "../pages/departments/drd/downloads";
import DRD_PhD_Vacancies from "../pages/departments/drd/phd-vacancies";
import DRD_Part1_Part2_subjects from "../pages/departments/drd/part-1-part-2-subjects";
import DFD_Committee from "../pages/departments/dfd/committee";
import DFD_SSS_Minutes from "../pages/departments/dfd/sss-minutes";
import DFD_Feedback from "../pages/departments/dfd/feedback";
import DFD_Certificates from "../pages/departments/dfd/certificates";
import DFD_NIRF from "../pages/departments/dfd/dfd-nirf";
import DFD_Green_Audit from "../pages/departments/dfd/green-audit";
import DFD_Environment_Audit from "../pages/departments/dfd/environment-audit";
import DFD_Energy_Audit from "../pages/departments/dfd/energy-audit";
import DFD_ISO from "../pages/departments/dfd/iso";
import DFD_THE_World_University_Rankings from "../pages/departments/dfd/t-h-e-world-university-rankings";
import DFD_QS_World_Ranking from "../pages/departments/dfd/qs-world-ranking";
import DFD_Guidelines from "../pages/departments/dfd/guidelines";
import DFD_Activities from "../pages/departments/dfd/activities";
import RTI_ACT from "../pages/rti-act";
import JNTUA_Courses_Offered from "../pages/jntua-courses-offered";
import DAP_Carriculum from "../pages/departments/dap/carriculum";
import NewsBulletinDetails from "../pages/news-bulletin-details";
import Research_Seed_Grant from "../pages/sr/research-seed-grant";
import NSS_Unit_Under_JNTUA from "../pages/departments/nss/nss-unit-under-jntua";
import NSS_Achievements from "../pages/departments/nss/nss-achievements";
import NSS_Activities_College_2016_17 from "../pages/departments/nss/activities-college-2016-17";
import NSS_Activities_College_2017_18 from "../pages/departments/nss/activities-college-2017-18";
import NSS_Activities_College_2018_19 from "../pages/departments/nss/activities-college-2018-19";
import NSS_Special_Camps_2017_18 from "../pages/departments/nss/special-camps-2017-18";
import NSS_Special_Camps_2018_19 from "../pages/departments/nss/special-camps-2018-19";
import NSS_Innovative_Activities from "../pages/departments/nss/innovative-activities";
import NSS_Program_Officers from "../pages/departments/nss/nss-program-officers";
import SDIC_Events from "../pages/departments/sdic/events";
import SDIC_Company_Linkages from "../pages/departments/sdic/company-linkages";
import SDIC_Services from "../pages/departments/sdic/services";
import SDIC_Startups from "../pages/departments/sdic/startups";
import SDIC_Partners from "../pages/departments/sdic/partners";
import SDIC_Incubatee from "../pages/departments/sdic/incubatee";
import Events_Organized from "../pages/departments/sdic/events-organized";
import SDIC_Mentors from "../pages/departments/sdic/mentors";
import UnderDevelopment from "../pages/underDevelopment";
import SDIC_Mentors_Alumni from "../pages/departments/sdic/alumni";
import SDIC_Mentors_Investors from "../pages/departments/sdic/investors";
import SDIC_Mentors_Industry from "../pages/departments/sdic/industry";
import SDIC_Dassult_Systems from "../pages/departments/sdic/dassult-systems";
import SDIC_SCOE from "../pages/departments/sdic/siemens-centre-of-excellence";
import SDIC_Candidates from "../pages/departments/sdic/candidates";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/vision-mission" component={Mission} />
          <Route path="/university-goals" component={UniversityGoals} />
          <Route path="/jntua-policies" component={JNTUA_Policies} />
          <Route path="/iqac/:type?" component={IQAC} />
          <Route path="/phd-courses" component={PHD_Courses} />
          <Route path="/rti-act" component={RTI_ACT} />
          <Route path="/SDD" component={SDD} />
          <Route path="/institutional-bodies" component={InstitutionalBody} />
          <Route path="/university-song" component={UniversitySong} />
          <Route path="/convocations/:id" component={Convocation} />
          <Route path="/convocations" component={Convocations} />
          <Route path="/profile/:type?" component={Profile} />
          <Route
            path="/controller-of-examinations-pg"
            component={ExaminationUG}
          />
          <Route path="/honoris-causa" component={HonorisCausa} />
          <Route path="/executive-council" component={ExecutiveCouncil} />
          <Route path="/tie-ups-mous" component={TieUpsMOUs} />
          <Route path="/directorates" component={Directorates} />
          <Route path="/board-of-studies" component={BoardOfStudies} />
          <Route path="/contact-us/:type?" component={JNTUA_Contacts} />
          <Route path="/governing-hierarchy" component={GoverningHierarchy} />
          <Route path="/methodology" component={Methodology} />
          <Route
            path="/syllabus/post-graduate-courses"
            component={Post_Graduate_Courses}
          />
          <Route
            path="/syllabus/under-graduate-courses"
            component={Under_Graduate_Courses}
          />
          <Route path="/syllabus" component={Regulations_Syllabus} />
          <Route path="/jntua-nirf" component={JNTUA_NIRF} />
          <Route path="/jntua-aishe" component={JNTUA_AISHE} />
          <Route path="/nirf-reports" component={NIRF_Reports} />
          <Route path="/aishe-reports" component={AISHE_Reports} />
          <Route path="/annual-reports" component={AnnualReports} />
          <Route path="/e-magazines" component={E_Magazine} />
          <Route
            path="/guidelines-for-donation"
            component={Guidelines_for_Donation}
          />
          <Route path="/research-development" component={ResearchDevelopment} />
          <Route path="/news-letters" component={JNTUANewsLetters} />
          <Route
            path="/registered-ph-d-scholars"
            component={RegisteredPhDScholars}
          />
          <Route
            path="/examination-branch-application-forms"
            component={EBAForms}
          />
          <Route path="/archive" component={ArchiveFiles} />
          <Route path="/admissions" component={Admissions} />
          <Route path="/academicsdata/:type?" component={CircularAspects} />
          <Route
            path="/teachingLearnProcess"
            component={Teaching_Learning_Process}
          />
          <Route
            path="/studentsupportprogression"
            component={Student_Support_And_Progression}
          />
          <Route
            path="/infrastructurelearning/:type?"
            component={Infrastructure_and_Learning_Resources}
          />
          <Route path="/autonomous-colleges" component={AutonomousColleges} />
          <Route
            path="/university-examination-fee-structure-of-various-courses"
            component={University_Examination_Fee_Structure}
          />
          <Route
            path="/addl-controller-of-examinations"
            component={Addl_Controller_of_Examinations}
          />
          <Route
            path="/programmes-offered/:type"
            component={ProgrammesOfferedList}
          />
          <Route path="/programmes-offered" component={ProgrammesOffered} />
          <Route path="/courses-offered" component={JNTUA_Courses_Offered} />
          <Route path="/under-graduate" component={UnderGraduate} />
          <Route path="/post-graduate" component={PostGraduate} />
          <Route path="/course/:id" component={Courses} />
          <Route path="/about" component={About} />
          <Route
            path="/vice-chancellor-message"
            component={Vice_Chancellor_Message}
          />
          <Route
            path="/special-officers-profile/:id"
            component={SpecialOfficersProfile}
          />
          <Route path="/special-officers" component={SpecialOfficers} />
          <Route
            path="/affiliated-colleges-anantapur"
            component={Affiliated_Colleges_East_Godavari}
          />
          <Route
            path="/affiliated-colleges-chittoor"
            component={Affiliated_Colleges_West_Godavari}
          />
          <Route
            path="/affiliated-colleges-y-s-r"
            component={Affiliated_Colleges_Krishna}
          />
          <Route
            path="/affiliated-colleges-kurnool"
            component={Affiliated_Colleges_Guntur}
          />
          <Route
            path="/affiliated-colleges-nellore"
            component={Affiliated_Colleges_Prakasam}
          />
          <Route path="/affiliated-colleges" component={Affiliated_Colleges} />
          <Route path="/naac/:type" component={NAACReports} />
          <Route path="/location-map" component={Location} />
          <Route path="/terms-conditions" component={Terms} />
          <Route path="/website-policy" component={WebPolicy} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/category" component={Notifications} />
          <Route path="/notification" component={NotificationDetails} />
          <Route
            path="/former-vice-chancellors"
            component={JNTUKViceChancellor}
          />
          <Route path="/campus-buildings" component={CampusBuildings} />
          <Route path="/gallery/:type?" component={Gallery} />
          <Route path="/gallery-all" component={GalleryALL} />
          <Route
            path="/download-app-formats/:type?"
            component={DownloadAppFormats}
          />
          <Route path="/our-faculty/:type?" component={Faculty} />
          {/* Departments Routes */}
          {/* DAP Routes */}
          <Route exact path="/dap" component={CommonHome} />
          <Route path={"/dap/contact-us"} component={CommonContact} />
          <Route
            path={"/dap/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route
            path={"/dap/research-programmes"}
            component={Research_Programmes}
          />
          <Route path="/dap/category/:type?" component={News_Bulletin} />
          <Route path="/dap/notification" component={NewsBulletinDetails} />
          <Route path={"/dap/newsletter"} component={CommonNewsletter} />
          <Route path={"/dap/ug-programmes"} component={UG_Programmes} />
          <Route path={"/dap/regular-courses"} component={Regular_Courses} />
          <Route path={"/dap/part-time"} component={Part_Time} />
          <Route
            path={"/dap/integrated-dual-degree-programmes"}
            component={Dual_Degree_Programmes}
          />
          <Route path={"/dap/mous-list"} component={Mous_List} />
          <Route path="/dap/academic-senate" component={AcademicSenate} />
          <Route path={"/dap/downloads"} component={CommonDownloads} />
          <Route
            path={"/dap/curriculum/:type?/:course?"}
            component={DAP_Carriculum}
          />
          <Route path="/dap/gallery" component={CommonGallery} />
          <Route path="/dap/gallery-all" component={CommonGalleryALL} />
          {/* DA Routes */}
          <Route exact path="/da" component={CommonHome} />
          <Route path={"/da/contact-us"} component={CommonContact} />
          <Route
            path={"/da/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/da/newsletter"} component={CommonNewsletter} />
          <Route path="/da/gallery" component={CommonGallery} />
          <Route path="/da/gallery-all" component={CommonGalleryALL} />
          {/* DE Routes */}
          <Route exact path="/de" component={CommonHome} />
          <Route path={"/de/contact-us"} component={CommonContact} />
          <Route
            path={"/de/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/de/newsletter"} component={CommonNewsletter} />
          <Route path="/de/gallery" component={CommonGallery} />
          <Route path="/de/gallery-all" component={CommonGalleryALL} />
          {/* DRD Routes */}
          <Route exact path="/drd" component={CommonHome} />
          <Route path={"/drd/contact-us"} component={CommonContact} />
          <Route
            path={"/drd/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/drd/newsletter"} component={CommonNewsletter} />
          <Route path={"/drd/downloads"} component={DRD_Downloads} />
          <Route
            path={"/drd/ph-d-scholars-admitted"}
            component={DRD_PhD_Scholars_Admitted_Year_Wise}
          />
          <Route path={"/drd/regulations"} component={DRD_Regulations} />
          <Route
            path={"/drd/part-1-part-2-subjects"}
            component={DRD_Part1_Part2_subjects}
          />
          <Route path={"/drd/ph-d-vacancies"} component={DRD_PhD_Vacancies} />
          <Route path="/drd/gallery" component={CommonGallery} />
          <Route path="/drd/gallery-all" component={CommonGalleryALL} />
          {/* DIRP Routes */}
          <Route exact path="/dirp" component={CommonHome} />
          <Route path={"/dirp/contact-us"} component={CommonContact} />
          <Route
            path={"/dirp/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/dirp/newsletter"} component={CommonNewsletter} />
          <Route path={"/dirp/placements"} component={DIRP_Placements} />
          <Route path="/dirp/gallery" component={CommonGallery} />
          <Route path="/dirp/gallery-all" component={CommonGalleryALL} />
          {/* DICS Routes */}
          <Route exact path="/dics" component={CommonHome} />
          <Route path={"/dics/contact-us"} component={CommonContact} />
          <Route
            path={"/dics/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path="/dics/gallery" component={CommonGallery} />
          <Route path="/dics/gallery-all" component={CommonGalleryALL} />
          {/* DFAAM Routes */}
          <Route exact path="/dfaam" component={CommonHome} />
          <Route path={"/dfaam/contact-us"} component={CommonContact} />
          <Route
            path={"/dfaam/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/dfaam/newsletter"} component={CommonNewsletter} />
          <Route path={"/dfaam/mous"} component={DFAAM_MOU} />
          <Route
            path={"/dfaam/list-of-foreign-students"}
            component={DFAAM_List_of_Foreign_Students}
          />
          <Route
            path={"/dfaam/notifications"}
            component={DFAAM_Notifications}
          />
          <Route path={"/dfaam/emratus"} component={DFAAM_Emratus} />
          <Route
            path={"/dfaam/12b-2f-certificates"}
            component={DFAAM_12b_2f_Certificates}
          />
          <Route
            path={"/dfaam/sponsored-gold-medals"}
            component={DFAAM_Sponsored_Gold_Medals}
          />
          <Route path="/dfaam/gallery" component={CommonGallery} />
          <Route path="/dfaam/gallery-all" component={CommonGalleryALL} />
          {/* DFD Routes */}
          <Route exact path="/dfd" component={UnderDevelopment} />
          <Route exact path="/diqac" component={CommonHome} />
          <Route path={"/diqac/contact-us"} component={CommonContact} />
          <Route path={"/diqac/committee"} component={DFD_Committee} />
          <Route path={"/diqac/sss-minutes"} component={DFD_SSS_Minutes} />
          <Route path={"/diqac/feedback"} component={DFD_Feedback} />
          <Route path={"/diqac/certificates"} component={DFD_Certificates} />
          <Route
            path={"/diqac/national-institutional-ranking-framework-n-i-r-f"}
            component={DFD_NIRF}
          />
          <Route path={"/diqac/green-audit-2"} component={DFD_Green_Audit} />
          <Route
            path={"/diqac/environment-audit-2"}
            component={DFD_Environment_Audit}
          />
          <Route path={"/diqac/energy-audit"} component={DFD_Energy_Audit} />
          <Route path={"/diqac/iso"} component={DFD_ISO} />
          <Route
            path={"/diqac/t-h-e-world-university-rankings"}
            component={DFD_THE_World_University_Rankings}
          />
          <Route
            path={"/diqac/qs-world-ranking"}
            component={DFD_QS_World_Ranking}
          />
          <Route path={"/diqac/guidelines"} component={DFD_Guidelines} />
          <Route path={"/diqac/activities"} component={DFD_Activities} />
          <Route path="/diqac/gallery" component={CommonGallery} />
          <Route path="/diqac/gallery-all" component={CommonGalleryALL} />
          {/* DSDC Routes */}
          <Route exact path="/sdc" component={CommonHome} />
          <Route path={"/sdc/contact-us"} component={CommonContact} />
          <Route
            path={"/sdc/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/sdc/newsletter"} component={CommonNewsletter} />
          <Route path={"/sdc/people"} component={DSDC_People} />
          <Route path="/sdc/gallery" component={CommonGallery} />
          <Route path="/sdc/gallery-all" component={CommonGalleryALL} />
          {/* DWEC Routes */}
          <Route exact path="/wec" component={CommonHome} />
          <Route path={"/wec/contact-us"} component={CommonContact} />
          <Route
            path={"/wec/about-directorate"}
            component={CommonAboutDirectorate}
          />
          <Route path={"/wec/newsletter"} component={CommonNewsletter} />
          <Route path={"/wec/programmes"} component={DWEC_Programmes} />
          <Route path={"/wec/notifications"} component={DWEC_Notifications} />
          <Route
            path={"/wec/internal-compliant-committee"}
            component={DWEC_Compliant}
          />
          <Route path="/wec/gallery" component={CommonGallery} />
          <Route path="/wec/gallery-all" component={CommonGalleryALL} />
          {/* SDIC Routes */}
          <Route exact path="/sdic" component={CommonHome} />
          <Route path={"/sdic/contact-us"} component={CommonContact} />
          <Route path={"/sdic/about-us"} component={CommonAboutUs} />
          <Route path={"/sdic/events"} component={SDIC_Events} />
          <Route
            path={"/sdic/company-linkages"}
            component={SDIC_Company_Linkages}
          />
          <Route
            path={"/sdic/dassult-systems/:type?"}
            component={SDIC_Dassult_Systems}
          />
          <Route
            path={"/sdic/siemens-centre-of-excellence/:type?"}
            component={SDIC_SCOE}
          />
          <Route path={"/sdic/services"} component={SDIC_Services} />
          <Route path={"/sdic/ic-events"} component={Events_Organized} />
          <Route path={"/sdic/startups"} component={SDIC_Startups} />
          <Route path={"/sdic/partners"} component={SDIC_Partners} />
          <Route path={"/sdic/incubatee"} component={SDIC_Incubatee} />
          <Route
            path={"/sdic/mentors/alumni"}
            component={SDIC_Mentors_Alumni}
          />
          <Route path={"/sdic/investors"} component={SDIC_Mentors_Investors} />
          <Route
            path={"/sdic/mentors/industry"}
            component={SDIC_Mentors_Industry}
          />
          <Route path={"/sdic/mentors"} component={SDIC_Mentors} />
          <Route path="/sdic/gallery" component={CommonGallery} />
          <Route path="/sdic/gallery-all" component={CommonGalleryALL} />
          <Route
            path={"/sdic/computer-aided-design-cad"}
            component={SDIC_Candidates}
          />
          <Route path={"/sdic/mechatronics"} component={SDIC_Candidates} />
          <Route
            path={"/sdic/automotive-body-painting"}
            component={SDIC_Candidates}
          />{" "}
          <Route path={"/sdic/robotics"} component={SDIC_Candidates} />
          <Route path={"/sdic/electrical"} component={SDIC_Candidates} />
          <Route
            path={"/sdic/process-instrumentation"}
            component={SDIC_Candidates}
          />
          <Route path={"/sdic/cnc-programming"} component={SDIC_Candidates} />
          <Route path={"/sdic/cnc-machines"} component={SDIC_Candidates} />
          <Route path={"/sdic/robcad"} component={SDIC_Candidates} />
          <Route path={"/sdic/automation"} component={SDIC_Candidates} />
          {/* NSS Routes */}
          <Route exact path="/nss" component={CommonHome} />
          <Route path={"/nss/contact-us"} component={CommonContact} />
          <Route path={"/nss/about-us"} component={CommonAboutUs} />
          <Route path={"/nss/downloads"} component={CommonDownloads} />
          <Route
            path={"/nss/nss-units-under-jntua"}
            component={NSS_Unit_Under_JNTUA}
          />
          <Route
            path={"/nss/about-nss-cell/achievements"}
            component={NSS_Achievements}
          />
          <Route
            path={"/nss/about-nss-cell/activities-college-wise-2016-17"}
            component={NSS_Activities_College_2016_17}
          />
          <Route
            path={"/nss/about-nss-cell/activities-college-wise-2017-18"}
            component={NSS_Activities_College_2017_18}
          />
          <Route
            path={"/nss/about-nss-cell/activities-college-wise-2018-19"}
            component={NSS_Activities_College_2018_19}
          />
          <Route
            path={"/nss/about-nss-cell/special-camp-2017-18"}
            component={NSS_Special_Camps_2017_18}
          />
          <Route
            path={"/nss/about-nss-cell/special-camp-2018-19"}
            component={NSS_Special_Camps_2018_19}
          />
          <Route
            path={"/nss/about-nss-cell/innovative-activities"}
            component={NSS_Innovative_Activities}
          />
          <Route
            path={"/nss/about-nss-cell/nss-program-officers"}
            component={NSS_Program_Officers}
          />
          <Route path="/nss/gallery" component={CommonGallery} />
          <Route path="/nss/gallery-all" component={CommonGalleryALL} />
          {/* SCST Routes */}
          <Route exact path="/scst" component={CommonHome} />
          <Route path={"/scst/contact-us"} component={CommonContact} />
          <Route path={"/scst/about-us"} component={CommonAboutUs} />
          <Route path={"/scst/downloads"} component={CommonDownloads} />
          <Route path={"/scst/others"} component={CommonOthers} />
          <Route path="/scst/gallery" component={CommonGallery} />
          <Route path="/scst/gallery-all" component={CommonGalleryALL} />
          {/* Sports Routes */}
          <Route exact path="/sports" component={CommonHome} />
          <Route path={"/sports/contact-us"} component={CommonContact} />
          <Route path={"/sports/about-us"} component={CommonAboutUs} />
          <Route path={"/sports/downloads"} component={CommonDownloads} />
          <Route path={"/sports/others"} component={CommonOthers} />
          <Route path="/sports/gallery" component={CommonGallery} />
          <Route path="/sports/gallery-all" component={CommonGalleryALL} />
          {/* ARC Routes */}
          <Route exact path="/arc" component={CommonHome} />
          <Route path={"/arc/contact-us"} component={CommonContact} />
          <Route path={"/arc/about-us"} component={CommonAboutUs} />
          <Route path={"/arc/downloads"} component={CommonDownloads} />
          <Route path={"/arc/others"} component={CommonOthers} />
          <Route path="/arc/gallery" component={CommonGallery} />
          <Route path="/arc/gallery-all" component={CommonGalleryALL} />
          {/* SR Routes */}
          <Route exact path="/sr" component={CommonHome} />
          <Route
            path="/sr/research-seed-grant"
            component={Research_Seed_Grant}
          />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
