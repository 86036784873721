import React, { useEffect, useState } from "react";
import { AdministrationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import AboutPart from "./AboutPart";
import { useParams } from "react-router-dom";
import ScrollToTop from "../../components/Common/ScrollTop";
import {
  VisionMission,
  Profile,
  Notifications,
} from "../../components/Profile";
import GalleryPart from "../../components/Gallary/gallary";
import RightSidebar from "../../components/Common/DirectoratesRightSidebar";

const Content = ({ setTitle }) => {
  const [data, setData] = useState([]);
  const { type } = useParams();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [path, setPath] = useState(null);
  useEffect(() => {
    setShowNotifications(false);
    setNotifications([]);
    setPath(null);
    setData([]);
    setTitle("");
    if (type === "chancellor") {
      setTitle("Chancellor");
      fetchData(JNTUK_API_Urls.Chancellor, setData);
    } else if (type === "vice-chancellor") {
      setTitle("Vice Chancellor");
      fetchData(JNTUK_API_Urls.Vice_Chancellor, setData);
    } else if (type === "rector") {
      setTitle("Rector");
      fetchData(JNTUK_API_Urls.Rector, setData);
    } else if (type === "public-relations-office") {
      setTitle("Public Relations Office");
      fetchData(JNTUK_API_Urls.Public_Relations_Department, setData);
    } else if (
      type === "director-of-skill-development-centre-incubation-centre"
    ) {
      setPath({ path: "/sdic", label: "SDIC" });
      setTitle("Skill Development Centre & Incubation Centre");
      fetchData(JNTUK_API_Urls.SDIC_Department, setData);
    } else if (type === "dirp-coordinator") {
      setTitle("DIRP Coordinator");
      fetchData(JNTUK_API_Urls.DIRP_Coordinator, setData);
    } else if (type === "nss") {
      setPath({ path: "/nss", label: "NSS" });
      setTitle("NSS CELL");
      fetchData(JNTUK_API_Urls.NSS_Department, setData);
    } else if (type === "scst") {
      setPath({ path: "/scst", label: "SCST" });
      setTitle("SC / ST CELL");
      fetchData(JNTUK_API_Urls.SCST_Department, setData);
    } else if (type === "sports") {
      setPath({ path: "/sports", label: "SPORTS" });
      setTitle("Sports Council");
      fetchData(JNTUK_API_Urls.SPORTS_Department, setData);
    } else if (type === "arc") {
      setPath({ path: "/arc", label: "ARC" });
      setTitle("Anti Ragging Cell");
      fetchData(JNTUK_API_Urls.ARC_Department, setData);
    } else if (type === "registrar") {
      setTitle("Registrar");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.Registrar, setData);
    } else if (type === "controller-of-examinations-pg") {
      setTitle("Controller of Examinations");
      fetchData(JNTUK_API_Urls.JNTUA_COE_Department, setData);
    } else if (type === "academics-and-planning-dap") {
      setPath({ path: "/dap", label: "DAP" });
      setTitle("Director, Academic Planning");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DAP_Department, setData);
      fetchData(
        JNTUK_API_Urls.DAP_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-academic-audit") {
      setTitle("Director, Academic Audit");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DAA_Department, setData);
      fetchData(
        JNTUK_API_Urls.DAA_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-evaluation-de") {
      setPath({ path: "/de", label: "DE" });
      setTitle("Director of Evaluation (DE)");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DE_Department, setData);
      fetchData(
        JNTUK_API_Urls.DE_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "admissions-da") {
      setPath({ path: "/da", label: "DA" });
      setTitle("Director, Admissions");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DA_Department, setData);
      fetchData(
        JNTUK_API_Urls.DA_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-industrial-relations-placements") {
      setPath({ path: "/dirp", label: "DIRP" });
      setTitle("Industrial Relations & Placements");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DIRP_Department, setData);
      fetchData(
        JNTUK_API_Urls.DIRP_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-research-and-development") {
      setPath({ path: "/drd", label: "DRD" });
      setTitle("Research and Development");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DRD_Department, setData);
      fetchData(
        JNTUK_API_Urls.DRD_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-faculty-development-iqac") {
      setPath({ path: "/dfd", label: "DFD" });
      setTitle("Faculty Development Centre");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DFD_Department, setData);
      fetchData(
        JNTUK_API_Urls.FDC_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-industrial-consultancy-services") {
      setPath({ path: "/dics", label: "DICS" });
      setTitle("Industrial Consultancy Services");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DICS_Department, setData);
      fetchData(
        JNTUK_API_Urls.DICS_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-foreign-affairs-alumni-matters") {
      setPath({ path: "/dfaam", label: "DFAAM" });
      setTitle("Foreign Affairs & Alumni Matters");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DFAAM_Department, setData);
      fetchData(
        JNTUK_API_Urls.DFAAM_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "school-of-management-studies") {
      setPath({ path: "https://jntuasms.ac.in/", label: "SMS" });
      setTitle("Director, School of Management Studies");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_SOMS_Department, setData);
      fetchData(
        JNTUK_API_Urls.SOMS_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "internal-quality-assurance-cell") {
      setPath({ path: "/diqac", label: "IQAC" });
      setTitle("Internal Quality Assurance Cell");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DIQAC_Department, setData);
      fetchData(
        JNTUK_API_Urls.DIQAC_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "software-development-centre") {
      setPath({ path: "/sdc", label: "SDC" });
      setTitle("Software Development Centre");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DSDC_Department, setData);
      fetchData(
        JNTUK_API_Urls.DSDC_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "sponsored-research") {
      setPath({ path: "/sr", label: "SR" });
      setTitle("Sponsored Research");
      setShowNotifications(false);
      fetchData(JNTUK_API_Urls.Sponsored_Research_Department, setData);
    } else if (type === "women-empowerment-cell") {
      setPath({ path: "/wec", label: "WEC" });
      setTitle("Women Empowerment Cell");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DWEC_Department, setData);
      fetchData(
        JNTUK_API_Urls.DWEC_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (
      type === "director-of-oil-technological-pharmaceutical-research-institute"
    ) {
      setPath({ path: "https://jntuaotpri.ac.in/director/", label: "OTPRI" });
      setTitle("Oil Technological & Pharmaceutical Research Institute");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.DOTPRI_Department, setData);
      fetchData(
        JNTUK_API_Urls.DOTPRI_Notifications +
          `&pagination[page]=${currentPage}`,
        setNotifications
      );
    }
  }, [type, currentPage]);

  let tab1 = "About",
    tab2 = "Profile",
    tab3 = "Gallery",
    tab4 = "Vision & Mission",
    tab5 = "Notifications";
  const tabStyle = "intro-tabs tabs-box";

  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 xl-mb-30">
              <div className="about-intro">
                <AdministrationMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-xl-9 pl-20 md-pl-14">
              <div className="content-wrapper intro-section">
                <h2 title={data?.data?.attributes?.Page_Heading}>
                  {data?.data?.attributes?.Page_Heading}
                </h2>
                <Tabs>
                  <TabList className={tabStyle}>
                    <Tab>
                      <button>{tab1}</button>
                    </Tab>
                    <Tab>
                      <button>{tab2}</button>
                    </Tab>
                    <Tab>
                      <button>{tab3}</button>
                    </Tab>
                    <Tab>
                      <button>{tab4}</button>
                    </Tab>
                    {showNotifications && (
                      <Tab>
                        <button onClick={() => setCurrentPage(1)}>
                          {tab5}
                        </button>
                      </Tab>
                    )}
                  </TabList>

                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-8">
                        <AboutPart data={data} pathObj={path} />
                      </div>
                      <div className="col-lg-4">
                        <RightSidebar data={data} />
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-8">
                        <Profile data={data} />
                      </div>
                      <div className="col-lg-4">
                        <RightSidebar data={data} />
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-8">
                        <GalleryPart data={data} />
                      </div>
                      <div className="col-lg-4">
                        <RightSidebar data={data} />
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="row">
                      <div className="col-lg-8">
                        <VisionMission data={data} />
                      </div>
                      <div className="col-lg-4">
                        <RightSidebar data={data} />
                      </div>
                    </div>
                  </TabPanel>
                  {showNotifications && (
                    <TabPanel>
                      <div className="row">
                        <div className="col-lg-8">
                          <Notifications
                            data={notifications}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        </div>
                        <div className="col-lg-4">
                          <RightSidebar data={data} />
                        </div>
                      </div>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
