import React, { useState, useEffect, useRef } from 'react';

const NotificationScroll = ({items, resetScroll }) => {
  const marqueeRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY);
    setStartScrollTop(marqueeRef.current.scrollTop);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaY = e.clientY - startY;
      marqueeRef.current.scrollTop = startScrollTop - deltaY;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    const marqueeContainer = marqueeRef.current;

    const scroll = () => {
      if (!isPaused && marqueeContainer) {
        marqueeContainer.scrollTop += 1; // Scroll down instead of up
        if (marqueeContainer.scrollTop >= marqueeContainer.scrollHeight - marqueeContainer.clientHeight) {
          marqueeContainer.scrollTop = 0; // Reset to the top
        }
      }
    };

    const intervalId = setInterval(scroll, 50);

    return () => {
      clearInterval(intervalId);
    };
  }, [isPaused]);

  const handleResetScroll = () => {
    marqueeRef.current.scrollTop = 0;
  };

  useEffect(() => {
    if (resetScroll) {
      handleResetScroll();
    }
  }, [resetScroll]);

  return (
    <React.Fragment>
      <div className="rs-team style1 nav-style2 orange-color md-pt-24 md-pb-20" onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className="">
          <div className='notification-list-area' ref={marqueeRef}>
            <div className='scrolling-items'>
              {items}
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default NotificationScroll;