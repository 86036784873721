import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { imageBaseURL } from "../../utils/fetchData";
const GalleryPart = ({ title, images,type }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [photos2, setPhotos2] = useState([]);
  React.useEffect(() => {
    if (images?.data?.length > 0) {
      const data = [];
      images?.data?.slice(0, 4)?.map((obj) => {
        if (obj?.attributes?.formats?.thumbnail)
          data.push({
            src: imageBaseURL + obj?.attributes?.formats?.thumbnail?.url,
            alt: obj?.attributes?.alternativeText || "", 
            title: obj?.attributes?.alternativeText || "",
          });
        else data.push({ 
          src: imageBaseURL + obj?.attributes?.url,
          alt: obj?.attributes?.alternativeText || "", 
          title: obj?.attributes?.alternativeText || "",
         });
      });
      setPhotos(data);
    }
  }, [images]);
  React.useEffect(() => {
    if (images?.data?.length > 0) {
      const data = [];
      images?.data?.map((obj) => {
        if (obj?.attributes)
          data.push({
            src: imageBaseURL + obj?.attributes?.url,
            alt: obj?.attributes?.alternativeText || "", 
            title: obj?.attributes?.alternativeText || "",
          });
        else data.push({ 
          src: imageBaseURL + obj?.attributes?.url,
          alt: obj?.attributes?.alternativeText || "", 
          title: obj?.attributes?.alternativeText || "",
         });
      });
      setPhotos2(data);
    }
  }, [images]);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <div className="rs-gallery pb-30 md-pt-80 md-pb-50">
        <div className="">
          <h6 className="mb-0" title={title}>{title}</h6>
          <Gallery direction={"row"} photos={photos} onClick={openLightbox} />
          <div className="position-relative z-1 mt-10">
            <Link
              className="readon orange-btn main-home text-capitalize"
              to={{ pathname: "/gallery-all", state: { title, images,type:type } }}
            >
              View All
            </Link>
          </div>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos2?.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </>
  );
};

export default GalleryPart;
