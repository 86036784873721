const currentDate = new Date();
const formattedDate = currentDate.toISOString();
export const JNTUK_API_Urls = {
  "Site-Map": "/site-maps",
  Our_Faculty: "/jntu-faculties?populate=*&sort=createdAt:asc",
  RTI_ACT: "rti-acts?populate=*&sort=createdAt:asc",
  "DAP-Home": "/dap-home?populate=*",
  "DAP-Contact-Us": "/dap-contact-us?populate=*",
  "DAP-Directorate": "/dap-directorate?populate=*",
  "DAP-Newsletter": "/dap-newsletters?populate=*&sort=createdAt:desc",
  "DAP-Downloads": "/dap-download?populate=*",
  "DAP-Curriculum": "/dap-curricula?populate=*&sort=createdAt:asc",
  "JNTUA-Courses-Offereds":
    "/jntua-courses-offereds?populate=*&sort=createdAt:asc",
  DAP_UG_Programmes:
    "/dap-courses-offereds?filters[Category][$contains]=U.G Programs&sort=createdAt:asc&populate=*",
  DAP_Recular_Courses:
    "/dap-courses-offereds?filters[Category][$contains]=Regular P.G Programs&sort=createdAt:asc&populate=*",
  DAP_Part_Time:
    "/dap-courses-offereds?filters[Category][$contains]=Part Time P.G Programs&sort=createdAt:asc&populate=*",
  Dual_Degree_Programmes: "/dap-courses-offereds?populate=*&sort=createdAt:asc",
  Mous_List: "/dap-mous-lists?populate=*&sort=createdAt:asc",
  "DAP-Research-Programmes": "/dap-research-programm?populate=*",
  "DAP-Gallery": "/dap-galleries?populate=*",
  "DAP-News-Bulletin": "/dap-news-bulletins?populate=*",
  "DA-Home": "/da-home?populate=*",
  "DA-Contact-Us": "/da-contact-us?populate=*",
  "DA-Directorate": "/da-directorate?populate=*",
  "DA-Newsletter": "/da-newsletters?populate=*&sort=createdAt:asc",
  "DA-Gallery": "/da-galleries?populate=*",

  "DE-Home": "/de-home?populate=*",
  "DE-Contact-Us": "/de-contact-us?populate=*",
  "DE-Directorate": "/de-directorate?populate=*",
  "DE-Newsletter": "/de-newsletters?populate=*&sort=createdAt:asc",
  "DE-Gallery": "/de-galleries?populate=*",

  "DRD-Home": "/drd-home?populate=*",
  "DRD-Contact-Us": "/drd-contact-us?populate=*",
  "DRD-Directorate": "/drd-directorate?populate=*",
  "DRD-Newsletter": "/drd-newsletters?populate=*&sort=createdAt:asc",
  "DRD-Downloads": "/drd-downloads?populate=*",
  "DRD-PhD-Vacancies": "/ph-d-vacancies?populate=*",
  "DRD-Part-1-Part-2-Subjects": "/drd-part-1-and-part-2-subjects?populate=*",
  "DRD-PhD-Scholars-Admitted-Year-Wise":
    "/drd-ph-d-scholars-admitted-year-wises?populate=*",
  "DRD-Regulations": "/drd-regulations?populate=*",
  "DRD-Gallery": "/drd-galleries?populate=*",

  "DIRP-Home": "/dirp-home?populate=*",
  "DIRP-Contact-Us": "/dirp-contact-us?populate=*",
  "DIRP-Directorate": "/dirp-directorate?populate=*",
  "DIRP-Placements": "/dirp-placements?populate=*",
  "DIRP-Newsletter": "/dirp-newsletters?populate=*&sort=createdAt:asc",
  "DIRP-Gallery": "/dirp-galleries?populate=*",

  "DICS-Home": "/dics-home?populate=*",
  "DICS-Contact-Us": "/dics-contact-us?populate=*",
  "DICS-Directorate": "/dics-directorate?populate=*",
  "DICS-Gallery": "/dics-galleries?populate=*",

  "DFAAM-Home": "/dfaam-home?populate=*",
  "DFAAM-Contact-Us": "/dfaam-contact-us?populate=*",
  "DFAAM-Directorate": "/dfaam-directorate?populate=*",
  "DFAAM-Newsletter": "/dfaam-newsletters?populate=*&sort=createdAt:asc",
  "DFAAM-Sponsored-Gold-Medals": "/dfaam-sponsored-gold-medal?populate=*",
  "DFAAM-Notifications-Files": "/dfaam-notifications-files?populate=*",
  "DFAAM-Emratus": "/dfaam-emratuses?populate=*",
  "DFAAM-12b-2f-certificates": "/dfaam-12-b-and-2-f-certificates?populate=*",
  "DFAAM-List-of-Foreign-Students": "/list-of-foreign-students?populate=*",
  "DFAAM-International-MoU": "/dfaam-international-mo-u-s?populate=*",
  "DFAAM-Gallery": "/dfaam-galleries?populate=*",

  "DFD-Home": "/dfd-home?populate=*",
  "DFD-Contact-Us": "/dfd-contact-us?populate=*",
  "DFD-Committee": "/dfd-committees?populate=*&sort=createdAt:asc",
  "DFD-SSS-Minutes": "/dfd-sss-minutes?populate=*&sort=createdAt:asc",
  "DFD-Feedback": "/dfd-feedbacks?populate=*&sort=createdAt:asc",
  "DFD-Certificates": "/dfd-certificates?populate=*&sort=createdAt:asc",
  "DFD-NIRF": "/dfd-nirfs?populate=*&sort=createdAt:asc",
  "DFD-Green-Audits": "/dfd-green-audits?populate=*&sort=createdAt:asc",
  "DFD-Environment-Audits":
    "/dfd-environment-audits?populate=*&sort=createdAt:asc",
  "DFD-Energy-Audits": "/dfd-energy-audits?populate=*&sort=createdAt:asc",
  "DFD-ISO": "/dfd-isos?populate=*&sort=createdAt:asc",
  "DFD-THE-World-University-Rankings":
    "/dfd-the-world-university-rankings?populate=*&sort=createdAt:asc",
  "DFD-QS-World-Rankings":
    "/dfd-qs-world-rankings?populate=*&sort=createdAt:asc",
  "DFD-Guidelines": "/dfd-guidelines?populate=*&sort=createdAt:asc",
  "DFD-Activities": "/dfd-activities?populate=*&sort=createdAt:asc",
  "DFD-Gallery": "/dfd-galleries?populate=*",

  "DSDC-Home": "/dsdc-home?populate=*",
  "DSDC-Contact-Us": "/dsdc-contact-us?populate=*",
  "DSDC-Directorate": "/dsdc-directorate?populate=*",
  "DSDC-Newsletter": "/dsdc-newsletters?populate=*&sort=createdAt:asc",
  "DSDC-People": "/dsdc-people?populate=*",
  "DSDC-Gallery": "/dsdc-galleries?populate=*",

  "DWEC-Home": "/dwec-home?populate=*",
  "DWEC-Contact-Us": "/dwec-contact-us?populate=*",
  "DWEC-Directorate": "/dwec-directorate?populate=*",
  "DWEC-Newsletter": "/dwec-newsletters?populate=*&sort=createdAt:asc",
  "DWEC-Programmes": "/wec-programme?populate=*",
  "DWEC-Notifications": "/wec-notification?populate=*",
  "DWEC-Compliant": "/dwec-internal-compliant-committees?populate=*",
  "DWEC-Gallery": "/dwec-galleries?populate=*",

  "SDIC-Home": "/sdic-home?populate=*",
  "SDIC-Contact-Us": "/sdic-contact-us?populate=*",
  "SDIC-About-Us": "/sdic-about?populate=*",
  "SDIC-Team": "/sdic-teams?populate=*",
  "SDIC-Gallery": "/sdic-galleries?populate=*",
  "SDIC-Events-Organized": "/sdic-events-organized?populate=*",
  "SDIC-Dassult-Systems-Introduction":
    "/sdic-dassult-systems-introduction?populate=*",
  "SDIC--OnBoard-Working-Employees":
    "/sdic-dassult-systems-on-board-working-employees?populate=*",
  "SDIC-News-Paper-Clippings": "/sdic-news-paper-clipping?populate=*",
  "SDIC-Photo-Gallery": "/sdic-photo-gallery?populate=*",
  "SDIC-Registration": "/sdic-registration?populate=*",
  "SDIC-Upcoming-Events": "/sdic-upcoming-event?populate=*",
  "SDIC-Video-Gallery": "/sdic-video-galleries?populate=*",

  "SDIC-SCOE-Video-Gallery": "/sdic-scoe-video-galleries?populate=*",
  "SDIC-SCOE-Photo-Gallery": "/sdic-scoe-photo-gallery?populate=*",
  "SDIC-SCOE-Registration": "/sdic-scoe-registration?populate=*",
  "SDIC-SCOE-In-Media": "/sdic-scoe-in-media?populate=*",
  "SDIC-SCOE-Onboard-Employees":
    "/sdic-scoe-onboard-working-employees?populate=*",
  "SDIC-SCOE-Brochures": "/sdic-scoe-brochures?populate=*",
  "SDIC-SCOE-Equipments": "/sdic-scoe-equipments?populate=*",
  "SDIC-SCOE-Equipments-Details": "/sdic-scoe-equipments-detail?populate=*",
  "SDIC-SCOE-Laboratories": "/sdic-scoe-laboratories?populate=*",
  "SDIC-SCOE-Candidates": "/sdic-scoe-candidates?populate=*",
  "SDIC-DS-Training-Students": "/sdic-ds-training-students?populate=*",

  "NSS-Home": "/nss-home?populate=*",
  "NSS-Contact-Us": "/nss-contact-us?populate=*",
  "NSS-About-Us": "/nss-about?populate=*",
  "NSS-Downloads": "/nss-download?populate=*",
  "NSS-Units-under-JNTUA": "/nss-units-under-jntuas?populate=*",
  "NSS-Program-Officers": "/nss-program-officers?populate=*",
  "NSS-Achievements": "/nss-achievements?populate=*",
  "NSS-Activities-Colleges": "/nss-activities-colleges?populate=*",
  "NSS-Special-Campus": "/nss-special-camps?populate=*",
  "NSS-Innovative-Activities": "/nss-innovative-activity?populate=*",
  "NSS-Gallery": "/nss-galleries?populate=*",

  "SCST-Home": "/scst-home?populate=*",
  "SCST-Contact-Us": "/scst-contact-us?populate=*",
  "SCST-About-Us": "/scst-about?populate=*",
  "SCST-Downloads": "/scst-download?populate=*",
  "SCST-Others": "/scst-other?populate=*",
  "SCST-Gallery": "/scst-galleries?populate=*",

  "SPORTS-Home": "/sports-home?populate=*",
  "SPORTS-Contact-Us": "/sports-contact-us?populate=*",
  "SPORTS-About-Us": "/sports-about?populate=*",
  "SPORTS-Downloads": "/sports-download?populate=*",
  "SPORTS-Others": "/sports-other?populate=*",
  "SPORTS-Gallery": "/sports-galleries?populate=*",

  "ARC-Home": "/arc-home?populate=*",
  "ARC-Contact-Us": "/arc-contact-us?populate=*",
  "ARC-About-Us": "/arc-about?populate=*",
  "ARC-Downloads": "/arc-download?populate=*",
  "ARC-Others": "/arc-other?populate=*",
  "ARC-Gallery": "/arc-galleries?populate=*",

  "SR-Home": "/sr-home?populate=*",

  About_JNTUK: "/about-jntuk?populate=*",
  Institutional_Body: "/institutional-body",
  JNTUA_Vision: "/jntua-vision-mission?populate=*",
  University_Goals: "/university-goal?populate=*",
  JNTUA_Policies: "/jntua-policies?populate=*&sort=createdAt:asc",
  PHD_Courses: "/phd-course?populate=*",
  Download_Application_Formats: "/download-application-formats?populate=*",
  SDD: "/sdd",
  IQAC: "/iqac?populate=*",
  Chancellor: "/chancellor?populate=*",
  Vice_Chancellor: "/vice-chancellor?populate=*",
  "SC_And_ST CELL":
    "/sc-and-st-cell?populate[0]=sc_and_st_cell_user&populate[1]=sc_and_st_cell_user.Image&populate[2]=Gallery",
  Former_Vice_Chancellors: "/former-vice-chancellors?sort=SrNo:asc&populate=*",
  Rector: "/rector?populate=*",
  Registrar: "/registrar?populate=*",
  OSD: "/osd?populate=*",
  Tenders: "/tenders?sort=Publish_Date:desc&populate=*",
  Press_Releases: "/press-releases?sort=Publish_Date:desc&populate=*",
  Latest_Events: "/latest-events?sort=Publish_Date:desc&populate=*",
  Executive_Council: "/jntuk-executive-councils?populate=*&sort=createdAt:asc",
  TieUpsMOUs: "/tie-ups-and-mous?populate=*&sort=Date_of_MOU:desc",
  HonorisCausa: "/honoris-causas?populate=*&sort=createdAt:asc",
  ResearchDevelopment:
    "/research-and-development-downloads?populate=*&sort=createdAt:asc",
  JNTUANewsLetters: "/jntua-news-letters?populate=*&sort=createdAt:asc",
  Post_Graduate_Syllabus:
    "/post-graduate-syllabi?populate=*&sort=createdAt:asc",
  Under_Graduate_Syllabus:
    "/under-graduate-syllabi?populate=*&sort=createdAt:asc",
  NAAC: "/naac-reports?populate=*&sort=createdAt:asc",
  NIRF_Reports: "/nirf-reports?populate=*&sort=createdAt:asc",
  AISHE_Reports: "/aishe-reports?populate=*&sort=createdAt:asc",
  AnnualReports: "/annual-reports?populate=*&sort=createdAt:asc",
  Research_Seed_Grant: "/sr-research-seed-grants?populate=*&sort=createdAt:asc",
  E_Magazine: "/e-magazines?populate=*&sort=Date:desc",
  EBAForms:
    "/examination-branch-application-forms?populate=*&sort=createdAt:asc",
  ArchiveFiles: "/archive-files?populate=*&sort=createdAt:asc",
  RegisteredPhDScholars:
    "registered-ph-d-scholars?populate=*&sort=createdAt:asc",
  DAP_Academic_Senate: "/dap-academic-senates?populate=*",
  Board_of_Studies: "/jntuk-board-of-studies?populate=*",
  Directorates: "/jntuk-directorates?populate=*",
  Special_Officers: "/jntuk-special-officers?populate=*",
  Infrastructure_and_Learning_Resources:
    "/jntuk-infrastructure-and-learning-resource",
  Methodology: "/methodology?populate=*",
  JNTUA_NIRF: "/jntua-nirf?populate=*",
  JNTUA_AISHE: "/jntua-aishe?populate=*",
  Guidelines_for_Donation: "/guidelines-for-donation?populate=*",
  Admissions: "/jntuk-admission",
  Teaching_Learning_Process: "/jntuk-teaching-learning-process",
  Student_Support_and_Progression: "/jntuk-student-support-and-progression",
  JNTUA_Contacts: "/jntua-contact-uses?populate=*",
  Autonomous_Colleges: "/jntuk-autonomous-colleges",
  Governing_Hierarchy: "/jntuk-governing-hierarchy?populate=*",
  Research: "/research",
  "Research Branches": "research-branches",
  Circular_Aspects: "/jntuk-circular-aspect",
  University_Gallery: "/university-galleries?populate=*",
  Campus_Buildings: "/jntuk-campus-building?populate=*",
  Academic_Events: "/academic-events?populate=*",
  Festivals: "/festivals?populate=*",
  University_Celebrations: "/university-celebrations?populate=*",
  Sports_Events: "/sports-events?populate=*",
  Convocations: "/jntuk-convocations?sort=Date_of_Convocation:desc&populate=*",
  JNTUK_PDF_Files_Uploads: "/jntuk-pdf-files-uploads",
  University_Examination_Fee_Structure: "/university-examination-fee-structure",
  Affiliated_Colleges: "/affiliated-colleges-download?populate=*",
  Affiliated_Colleges_Anantapur:
    "/affiliated-colleges?filters[District][$contains]=Anantapur",
  Affiliated_Colleges_Chittoor:
    "/affiliated-colleges?filters[District][$contains]=Chittoor",
  Affiliated_Colleges_Kurnool:
    "/affiliated-colleges?filters[District][$contains]=Kurnool",
  Affiliated_Colleges_YSR:
    "/affiliated-colleges?filters[District][$contains]=Y.S.R",
  Affiliated_Colleges_Nellore:
    "/affiliated-colleges?filters[District][$contains]=Nellore",
  Live_Scrolling: `/live-scrollings?filters[Start_Date][$lte]=${formattedDate}&filters[End_Date][$gte]=${formattedDate}&sort=Position:asc&sort=Start_Date:desc&populate=*`,
  University_Song: "/university-song?populate=*",
  Addl_Controller_of_Examinations:
    "/jntua-addl-coes?populate=*&sort=createdAt:asc",
  Controller_of_Examinations_PG: "/jntua-coes?populate=*&sort=createdAt:desc",
  Programmes_Offered: "/programmes-offereds",
  Home_Page_Banner: `/home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}&sort=createdAt:asc`,
  Under_Graduate: "/courses?filters[Degree][$contains]=Under Graduate",
  Post_Graduate: "/courses?filters[Degree][$contains]=Post Graduate",
  JNTUK_Directorates: "/jntuk-directorates?populate=*",
  JNTUK_DAP_Department:
    "/jntuk-dap-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  Public_Relations_Department:
    "/public-relations-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  SDIC_Department:
    "/sdic-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DIRP_Coordinator:
    "/dirp-coordinator?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  NSS_Department:
    "/nss-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  SCST_Department:
    "/scst-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  SPORTS_Department:
    "/sports-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  ARC_Department:
    "/arc-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  SR_Department:
    "/sponsored-research-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DAA_Department:
    "/jntuk-dap-director-audit?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DE_Department:
    "/jntuk-dap-director-evaluation?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DA_Department:
    "/jntuk-dap-director-admission?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DIRP_Department:
    "/irp-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DRD_Department:
    "/jntuk-drd-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DFD_Department:
    "/jntuk-fdc-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DICS_Department:
    "/ics-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DFAAM_Department:
    "/faam-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DSDC_Department:
    "/sdc-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  Sponsored_Research_Department:
    "/sponsored-research-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_SOMS_Department:
    "/jntuk-sms-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DIQAC_Department:
    "/iqac-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DWEC_Department:
    "/wec-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DOTPRI_Department:
    "/otpri-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  DAA_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Academic Audit",
  DAP_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Academic Planning",
  DE_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Director of Evaluation",
  DA_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Admissions",
  DIRP_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Industrial Relations & Placements",
  DRD_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Research and Development",
  FDC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Faculty Development Cell",
  DICS_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Industrial Consultancy Services",
  DFAAM_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Foreign Affairs & Alumni Matters",
  DSDC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Software Development Centre",
  SOMS_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=School of Management Studies",
  DIQAC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Internal Quality Assurance Cell",
  DWEC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Women Empowerment Cell",
  DOTPRI_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Oil Technological & Pharmaceutical Research Institute",
  Notification_Categories: "/notification-categories",
  JNTUA_COE_Department:
    "/coe-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  NAAC_Reports: "/naac-reports",
};
