import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/Layout/Header/Header";
import Footer from "../../../../components/Layout/Footer/Footer";
import { useLocation, useParams } from "react-router-dom";
import ScrollToTop from "../../../../components/Common/ScrollTop";
import OffWrap from "../../../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../../../components/Common/Breadcumb";
import SearchModal from "../../../../components/Layout/Header/SearchModal";
import footerLogo from "../../../../assets/img/logo/jntua-logo.png";
import { getMenu, getPageTitle } from "../../../../utils/getDomainData";
import { DassultSystemsMenu } from "../../../../components/Common/SidebarMenu";
import { fetchData, imageBaseURL } from "../../../../utils/fetchData";
import { JNTUK_API_Urls } from "../../../../utils/api.config";
import { BlocksRenderer } from "../../../../components/BlockRenderer";
import profIcon from "../../../../assets/img/profile-icon.png";
import GalleryPart from "../../../galleryAll/GalleryPart";
import Lab1Image from "../../../../assets/img/sdicLabImages/lab1.png";
import Lab2Image from "../../../../assets/img/sdicLabImages/lab2.png";

const SDIC_Dassult_Systems = () => {
  let location = useLocation();
  const currentDomain = location.pathname.split("/")[1];
  const { type } = useParams();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  useEffect(() => {
    setData([]);
    console.log("testing type", type);
    if (!type || type === "intro")
      fetchData(JNTUK_API_Urls["SDIC-Dassult-Systems-Introduction"], setData);
    else if (type === "employees")
      fetchData(JNTUK_API_Urls["SDIC--OnBoard-Working-Employees"], setData);
    else if (type === "training") {
      fetchData(
        JNTUK_API_Urls["SDIC-DS-Training-Students"] +
          "&filters[Category][$contains]=MTECH 1st Year Students list",
        setData1
      );
      fetchData(
        JNTUK_API_Urls["SDIC-DS-Training-Students"] +
          "&filters[Category][$contains]=MTECH 2nd Year Students list",
        setData2
      );
      fetchData(
        JNTUK_API_Urls["SDIC-DS-Training-Students"] +
          "&filters[Category][$contains]=BTECH 2nd Year Students list First Batch",
        setData3
      );
      fetchData(
        JNTUK_API_Urls["SDIC-DS-Training-Students"] +
          "&filters[Category][$contains]=BTECH 2nd Year Students list Second  Batch",
        setData4
      );
    } else if (type === "events")
      fetchData(JNTUK_API_Urls["SDIC-Upcoming-Events"], setData);
    else if (type === "registration")
      fetchData(JNTUK_API_Urls["SDIC-Registration"], setData);
    else if (type === "photo-gallery")
      fetchData(JNTUK_API_Urls["SDIC-Photo-Gallery"], setData);
    else if (type === "video-gallery")
      fetchData(JNTUK_API_Urls["SDIC-Video-Gallery"], setData);
    else if (type === "news")
      fetchData(JNTUK_API_Urls["SDIC-News-Paper-Clippings"], setData);
    else setData([]);
  }, [type]);
  const getTitle = (type) => {
    if (!type || type === "intro") return "Introduction to the Project";
    else if (type === "employees") return "OnBoard Working Employees";
    else if (type === "courses-offered") return "Courses Offered";
    else if (type === "lab-infrastructure") return "Lab Infrastructure";
    else if (type === "training") return "Training";
    else if (type === "events") return "Upcoming Events";
    else if (type === "registration") return "Registration";
    else if (type === "photo-gallery") return "Photo Gallery";
    else if (type === "video-gallery") return "Video Gallery";
    else if (type === "news") return "News Paper Clippings";
    else return "";
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Dassult Systems – {getPageTitle(currentDomain)}</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <div className="pageTopOffset">
        <SiteBreadcrumb
          pageTitle="Dassult Systems"
          pageName="Dassult Systems"
          breadcrumbsClass="breadcrumb-bg"
        />
        {getMenu(currentDomain)}
        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 xl-mb-30">
                <div className="about-intro">
                  <DassultSystemsMenu />
                </div>
              </div>
              <div className="col-xl">
                <div className="container">
                  <div className="row">
                    <div className="col-lg md-mb-30">
                      <div className="content-wrapper">
                        <div className="content-section">
                          <h3 title="Sponsored Gold Medals">
                            {getTitle(type)}
                          </h3>
                          <div style={{ marginTop: "10px" }}>
                            <BlocksRenderer
                              content={data?.data?.attributes?.Body || []}
                            />
                          </div>

                          {type === "employees" && (
                            <div
                              className="overflow-x"
                              style={{ marginTop: "20px" }}
                            >
                              <table className="table table-striped table-bordered">
                                <tbody>
                                  {data?.data?.length > 0 &&
                                    data?.data?.map((obj) => (
                                      <tr>
                                        <td>
                                          <img
                                            title="JNTUK Vice Chancellor's"
                                            alt="JNTUK Vice Chancellor's"
                                            style={{
                                              width: "250px",
                                              height: "250px",
                                            }}
                                            src={
                                              obj?.attributes?.Profile?.data
                                                ? imageBaseURL +
                                                  obj?.attributes?.Profile?.data
                                                    ?.attributes?.url
                                                : profIcon
                                            }
                                          />
                                        </td>
                                        <td>
                                          <BlocksRenderer
                                            content={
                                              obj?.attributes?.Details || []
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              <div
                                style={{
                                  marginTop: "20px",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  Contact: G. Ajith,
                                  ganthala.ajith@gramtarang.org.in, 9885488829
                                </p>
                              </div>
                            </div>
                          )}
                          {type === "video-gallery" && (
                            <div
                              className="video-gallery"
                              style={{
                                display: "flex",
                                flexWrap: "wrap", // Allow items to wrap to the next row
                                gap: "20px", // Add some space between items
                                marginTop: "20px",
                                justifyContent: "space-between",
                              }}
                            >
                              {data?.data?.length > 0 &&
                                data?.data?.map((obj, index) => (
                                  <iframe
                                    key={index}
                                    width="490"
                                    height="315"
                                    src={obj?.attributes?.Link}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                ))}
                            </div>
                          )}
                          {(type === "photo-gallery" || type === "news") && (
                            <div
                              className="photo-gallery"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                marginTop: "20px",
                                justifyContent: "space-between",
                              }}
                            >
                              {data?.data?.attributes?.Images?.data?.length >
                                0 && (
                                <GalleryPart
                                  title={""}
                                  images={data?.data?.attributes?.Images || []}
                                />
                              )}
                            </div>
                          )}
                          {type === "courses-offered" && <CoursesOffered />}
                          {type === "lab-infrastructure" && (
                            <LabInfrastructure />
                          )}
                          {type === "training" && (
                            <TrainingPage
                              data1={data1}
                              data2={data2}
                              data3={data3}
                              data4={data4}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      <ScrollToTop scrollClassName="scrollup secondaryColor" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SDIC_Dassult_Systems;

const CoursesOffered = () => {
  return (
    <div style={{ padding: "20px" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ddd",
        }}
      >
        <tbody>
          {/* First Course Section */}
          <tr>
            <td
              colSpan="3"
              style={{ padding: "10px", border: "1px solid #ddd" }}
            >
              <strong>Software Name: 3D Experience Platform</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Programme : Level – A</strong>
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Duration : 128 Hrs</strong>
            </td>
          </tr>
          <tr>
            <td
              colSpan="3"
              style={{ padding: "10px", border: "1px solid #ddd" }}
            >
              <strong>Modules in the Course:</strong>
            </td>
          </tr>
          <tr>
            <ol style={{ paddingLeft: "20px", marginTop: "10px" }}>
              <li>
                CATIA
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Sketcher</li>
                  <li>Part Design</li>
                  <li>Generative Shape Design</li>
                  <li>Assembly Design</li>
                  <li>Drafting</li>
                </ul>
              </li>
              <li>Delmia</li>
              <li>Simulia</li>
            </ol>
          </tr>

          {/* Second Course Section */}
          <tr>
            <td
              colSpan="3"
              style={{ padding: "10px", border: "1px solid #ddd" }}
            >
              <strong>Software Name: 3D Experience Platform</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Programme : Level – B</strong>
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Duration : 5 Days</strong>
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              style={{ padding: "10px", border: "1px solid #ddd" }}
            >
              <strong>Domain Training:</strong>
              <ol style={{ paddingLeft: "20px", marginTop: "10px" }}>
                <li>Automotive</li>
                <li>Aerospace and Defense</li>
                <li>Shipbuilding</li>
              </ol>
            </td>
          </tr>

          {/* Third Course Section */}
          <tr>
            <td
              colSpan="3"
              style={{ padding: "10px", border: "1px solid #ddd" }}
            >
              <strong>Software Name: 3D Experience Platform</strong>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Programme : Level – C</strong>
            </td>
            <td style={{ padding: "10px", border: "1px solid #ddd" }}>
              <strong>Ongoing Projects</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const LabInfrastructure = () => {
  return (
    <div
      style={{
        display: "flex",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* Content Area */}
      <div style={{ flex: 1 }}>
        <p>
          The center has been set up as a step to foster innovation and help
          instill the startup and research culture in the students as well act
          as a catalyst of growth by making world-class skilled professionals
          available to key growth sectors for the state and the country.
        </p>
        <p>
          The 3D Experience Lab is equipped with high end configured HP Laptops
          in count of 36nos provide by APSSDC, and the licenses for the software
          are provided by DASSAULT SYSTEMES in two types CLOUD basis and ON
          Premise.
        </p>

        {/* Current Infrastructure Table */}
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            border: "1px solid #ddd",
          }}
        >
          <tbody>
            <tr>
              <td
                colSpan="2"
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Current Infrastructure of the LAB
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Capacity of the lab
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                36 laptops
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                No. of Laptops Installed
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>36</td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                License type
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                Cloud
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                UPS
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>Yes</td>
            </tr>
          </tbody>
        </table>

        {/* Proposed Infrastructure Table */}
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            border: "1px solid #ddd",
          }}
        >
          <tbody>
            <tr>
              <td
                colSpan="2"
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Proposed Infrastructure of the LAB
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Capacity of the lab
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                100 laptops
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                License type
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                On Premise
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Cabin type
              </td>
              <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                Single/Partitioned
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={Lab1Image}
            alt="Lab1"
            style={{ marginRight: "10px", maxWidth: "100%", height: "auto" }}
          />
          <img
            src={Lab2Image}
            alt="Lab2"
            style={{ marginLeft: "10px", maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

const TrainingPage = ({ data1, data2, data3, data4 }) => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h3 style={{ textAlign: "center" }}>LEVEL A</h3>

      {/* Status of the Training Table */}
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <tbody>
          <tr>
            <td
              colSpan="2"
              style={{
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
              }}
            >
              Status of the Training
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              No. of Batches Initiated
            </td>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              5
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              No. of Batches Completed
            </td>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              4
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              No. of students completed
            </td>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              75
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "left",
              }}
            >
              No. of Students attended Exam
            </td>
            <td
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              45
            </td>
          </tr>
        </tbody>
      </table>

      <p style={{ marginTop: "20px" }}>
        Following students have completed LEVEL A course of CATIA, SIMULIA &
        DELMIA which was conducted for 128 hours.{" "}
        <strong>
          MTECH 1<sup>st</sup> Year Students list
        </strong>
      </p>

      {/* Student List Table 1 */}
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              S.No
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Student Name
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Registration Number
            </th>
          </tr>
        </thead>
        <tbody>
          {data1?.data?.map((obj, index) => (
            <tr key={index}>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Name}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Registration_Number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Student List Table 2 */}
      <p style={{ marginTop: "20px", fontWeight: "bold" }}>
        MTECH 2nd Year Students list
      </p>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              S.No
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Student Name
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Registration Number
            </th>
          </tr>
        </thead>
        <tbody>
          {data2?.data?.map((obj, index) => (
            <tr key={index}>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Name}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Registration_Number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Student List Table 3 */}
      <p style={{ marginTop: "20px", fontWeight: "bold" }}>
        BTECH 2nd Year Students list First Batch
      </p>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              S.No
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Student Name
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Registration Number
            </th>
          </tr>
        </thead>
        <tbody>
          {data3?.data?.map((obj, index) => (
            <tr key={index}>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Name}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Registration_Number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Student List Table 4 */}
      <p style={{ marginTop: "20px", fontWeight: "bold" }}>
        BTECH 2nd Year Students listSecond Batch
      </p>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              S.No
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Student Name
            </th>
            <th
              style={{
                backgroundColor: "#f0f0f0",
                fontWeight: "bold",
                padding: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              Registration Number
            </th>
          </tr>
        </thead>
        <tbody>
          {data4?.data?.map((obj, index) => (
            <tr key={index}>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Name}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                }}
              >
                {obj?.attributes?.Registration_Number}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
