"use client";

import React, { createContext, useContext, useMemo, useRef } from "react";

import { Block } from "./Block";

/* -------------------------------------------------------------------------------------------------
 * Default blocks and modifiers components
 * -----------------------------------------------------------------------------------------------*/

const defaultComponents = {
  blocks: {
    paragraph: (props) => <p>{props.children}</p>,
    quote: (props) => <blockquote>{props.children}</blockquote>,
    code: (props) => (
      <pre>
        <code>{props.plainText}</code>
      </pre>
    ),
    heading: ({ level, children }) => {
      switch (level) {
        case 1:
          return <h1 title={children[0]?.props.text}>{children}</h1>;
        case 2:
          return <h2 title={children[0]?.props.text}>{children}</h2>;
        case 3:
          return <h3 title={children[0]?.props.text}>{children}</h3>;
        case 4:
          return <h4 title={children[0]?.props.text}>{children}</h4>;
        case 5:
          return <h5 title={children[0]?.props.text}>{children}</h5>;
        case 6:
          return <h6 title={children[0]?.props.text}>{children}</h6>;
      }
    },
    link: (props) => (
      <a href={props.url} target="_blank">
        {props.children}
      </a>
    ),
    list: (props) => {
      if (props.format === "ordered") {
        return (
          <ol>
            {React.Children.map(props.children, (child, index) => (
              <li key={index}>{child}</li>
            ))}
          </ol>
        );
      }
      return (
        <ul>
          {React.Children.map(props.children, (child, index) => (
            <li key={index}>{child}</li>
          ))}
        </ul>
      );
    },
    "list-item": (props) => <>{props.children}</>,
    image: (props) => (
      <img
        style={{ marginBottom: "20px" }}
        src={props.image.url}
        alt={props.image.alternativeText || undefined}
        title={props.image.alternativeText || undefined}
      />
    ),
  },
  modifiers: {
    bold: (props) => <strong>{props.children}</strong>,
    italic: (props) => <em>{props.children}</em>,
    underline: (props) => <u>{props.children}</u>,
    strikethrough: (props) => <del>{props.children}</del>,
    code: (props) => <code>{props.children}</code>,
  },
  missingBlockTypes: [],
  missingModifierTypes: [],
};

/* -------------------------------------------------------------------------------------------------
 * Context to pass blocks and inline components to the nested components
 * -----------------------------------------------------------------------------------------------*/

const ComponentsContext = createContext(defaultComponents);

const ComponentsProvider = ({ children, value = defaultComponents }) => {
  const memoizedValue = useMemo(() => value, [value]);

  return (
    <ComponentsContext.Provider value={memoizedValue}>
      {children}
    </ComponentsContext.Provider>
  );
};

function useComponentsContext() {
  return useContext(ComponentsContext);
}

/* -------------------------------------------------------------------------------------------------
 * BlocksRenderer
 * -----------------------------------------------------------------------------------------------*/

const BlocksRenderer = (props) => {
  const blocks = {
    ...defaultComponents.blocks,
    ...props.blocks,
  };

  const modifiers = {
    ...defaultComponents.modifiers,
    ...props.modifiers,
  };

  const missingBlockTypes = useRef([]);
  const missingModifierTypes = useRef([]);

  return (
    <ComponentsProvider
      value={{
        blocks,
        modifiers,
        missingBlockTypes: missingBlockTypes.current,
        missingModifierTypes: missingModifierTypes.current,
      }}
    >
      {props.content.map((content, index) => (
        <Block content={content} key={index} />
      ))}
    </ComponentsProvider>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------------------------*/

export { ComponentsProvider, useComponentsContext, BlocksRenderer };
