import React,{useState,useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { Get_JNTUA_PDF_Links } from "../../../utils/fetchData";


function Accordion() {
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };
  
    fetchData();
  }, []);
  const items = [
    {
      label: "About",
      children: [
        {
          to: "/about",
          label: "Genesis- JNTUA",
          newTab: false,
        },
        {
          to: "/vision-mission",
          label: "Vision & Mission",
          newTab: false,
        },
        {
          to: "/university-goals",
          label: "University Goals",
          newTab: false,
        },
        {
          to: "/our-faculty",
          label: "Our Faculty",
          newTab: false,
        },
        {
          to: "#",
          label: "JNTUA Policies",
          newTab: false,
        },
        {
          to: "/honoris-causa",
          label: "Honoris Causa",
          newTab: false,
        },
        {
          to: "/governing-hierarchy",
          label: "Governing Hierarchy",
          newTab: false,
        },
        {
          to: "/gallery/univ-gallery",
          label: "JNTUA Gallery",
          newTab: false,
        },
        {
          to: "/tie-ups-mous",
          label: "Tie Ups & MOU’s",
          newTab: false,
        },
        {
          to: "/e-magazines",
          label: "e-Magazines",
          newTab: false,
        },
        {
          to: "https://www.youtube.com/channel/UCPa6B2sY8Zz13i1mOTyIBvA/videos",
          label: "75 Years Celebrations of JNTUACEA",
          newTab: true,
          pdfLink:true,
        },
        {
          to: "/university-song",
          label: "JNTUA Anthem",
          newTab: false,
        },
        {
          to: "/contact-us",
          label: "Contact Us",
          newTab: false,
          children: [
            {
              to: "/contact-us/university-officials",
              label: "Contact Details of University Officials",
              newTab: false,
            },
            {
              to: "/contact-us/examination-branch",
              label: "Examination Branch",
              newTab: false,
            },
            {
              to: "/contact-us/engineering-cell",
              label: "Engineering Cell",
              newTab: false,
            },
            {
              to: "/contact-us/co-ordinators",
              label: "Co-Ordinators",
              newTab: false,
            },
            {
              to: "/convocations",
              label: "Convocations",
              newTab: false,
            },
            {
              to: "/contact-us/sports-council",
              label: "Sports Council",
              newTab: false,
            },
            {
              to: "/contact-us/pro-peshi",
              label: "PRO / Peshi",
              newTab: false,
            },
            {
              to: "/contact-us/administrative-office-staff",
              label: "Administrative Office Staff",
              newTab: false,
            },
          ],
        },
  
        {
          to: "/location-map",
          label: "Location Map",
          newTab: false,
        },
      ],
    },
    {
      label: "Administration",
      children: [
        {
          to: "/profile/chancellor",
          label: "Chancellor",
          newTab: false,
        },
        {
          to: "/profile/vice-chancellor",
          label: "Vice Chancellor",
          newTab: false,
        },
        {
          to: "/profile/rector",
          label: "Rector",
          newTab: false,
        },
        {
          to: "/profile/registrar",
          label: "Registrar",
          newTab: false,
        },
        {
          to: "/executive-council",
          label: "Executive Council",
          newTab: false,
        },
        {
          to: "#",
          label: "University Administration / Directorate’s",
          newTab: false,
        },
        {
          to: "/former-vice-chancellors",
          label: "Former Vice Chancellors",
          newTab: false,
        },
        {
          to: "/directorates",
          label: "Directorates",
          newTab: false,
          children: [
            {
              to: "/profile/academics-and-planning-dap",
              label: "Academic Planning",
              newTab: false,
            },
            {
              to: "/profile/director-academic-audit",
              label: "Academic Audit",
              newTab: false,
            },
            {
              to: "/profile/admissions-da",
              label: "Admissions",
              newTab: false,
            },
            {
              to: "/profile/director-of-evaluation-de",
              label: "Director Of Evaluation",
              newTab: false,
            },
            {
              to: "/profile/director-research-and-development",
              label: "Research And Development",
              newTab: false,
            },
            {
              to: "/profile/director-of-industrial-relations-placements",
              label: "Industrial Relations & Placements",
              newTab: false,
            },
            {
              to: "/profile/director-of-industrial-consultancy-services",
              label: "Industrial Consultancy Services",
              newTab: false,
            },
            {
              to: "/profile/director-of-foreign-affairs-alumni-matters",
              label: "Foreign Affairs & Alumni Matters",
              newTab: false,
            },
            {
              to: "/profile/director-of-faculty-development-iqac",
              label: "Faculty Development",
              newTab: false,
            },
            {
              to: "/profile/internal-quality-assurance-cell",
              label: "Internal Quality Assurance Cell",
              newTab: false,
            },
            {
              to: "/profile/software-development-centre",
              label: "Software Development Centre",
              newTab: false,
            },
            {
              to: "/profile/sponsored-research",
              label: "Sponsored Research",
              newTab: false,
            },
            {
              to: "/profile/women-empowerment-cell",
              label: "Women Empowerment Cell",
              newTab: false,
            },
          
            {
              to: "/profile/director-of-oil-technological-pharmaceutical-research-institute",
              label: "Oil Technological & Pharmaceutical Research Institute",
              newTab: false,
            },
            {
              to: "/profile/school-of-management-studies",
              label: "School of Management Studies",
              newTab: false,
            },
          ],
        },
      ],
    },
    {
      label: "Academics",
      children: [
        {
          to: "/methodology",
          label: "Methodology",
          newTab: false,
        },
        {
          to: "/admissions",
          label: "Admission",
          newTab: false,
        },
        {
          to: "/syllabus",
          label: "Regulations & Syllabus",
          newTab: false,
        },
        {
          to: "/programmes-offered",
          label: "Programmes Offered",
          newTab: false,
        },
        {
          to: "/under-graduate",
          label: "Under Graudate",
          newTab: false,
          children: [
            {
              to: "/course/1",
              label: "Bachelor of Technology",
              newTab: false,
            },
            {
              to: "/course/2",
              label: "Bachelor of Pharmacy",
              newTab: false,
            },
          ],
        },
        {
          to: "/post-graduate",
          label: "Post Graduate",
          newTab: false,
          children: [
            {
              to: "/course/5",
              label: "Master of Technology",
              newTab: false,
            },
            {
              to: "/course/6",
              label: "Master of Pharmacy",
              newTab: false,
            },
            {
              to: "/course/7",
              label: "Master of Computer Applications",
              newTab: false,
            },
            {
              to: "/course/8",
              label: "Master of Bussiness Administration",
              newTab: false,
            },
          ],
        },
        {
          to: "/phd-courses",
          label: "Phd Courses",
          newTab: false,
        },
        {
          to: "#",
          label: "EXAMINATIONS",
          newTab: false,
          children: [
            {
              to: "/profile/director-of-evaluation-de",
              label: "Director of Evaluation",
              newTab: false,
            },
            {
              to: "/profile/controller-of-examinations-pg",
              label: "Controller of Examinations",
              newTab: false,
            },
            {
              to: "/addl-controller-of-examinations",
              label: "Addl. Controller of Examinations",
              newTab: false,
            },
            {
              to: "/examination-branch-application-forms",
              label: "Downloadable Documents",
              newTab: false,
            },
            {
              to: "https://jntuaebranchpayment.in/",
              label: "Examination Branch Online Applications",
              newTab: true,
              pdfLink:true,
            },
            {
              to: "https://www.icredify.com/degreeverify/jntua",
              label: "Degree Verification",
              newTab: true,
              pdfLink:true,
            },
            {
              to: "/category/University-Examinations",
              label: "Exams Calendars",
              newTab: false,
            },
            {
              to: "https://jntuaresults.ac.in/",
              label: "Results",
              newTab: true,
              pdfLink:true,
            },
          ],
        },
  
        {
          to: "#",
          label: "Constituent Colleges",
          newTab: false,
          children: [
            {
              to: "https://www.jntuacea.ac.in",
              label: "JNTUA - Ananthapuramu",
              newTab: true,
              isExternal: true,
            },
            {
              to: "https://www.jntuacep.ac.in",
              label: "JNTUA - Pulivendula",
              newTab: true,
              isExternal: true,
            },
            {
              to: "https://www.jntuacek.ac.in",
              label: "JNTUA - Kalikiri",
              newTab: true,
              isExternal: true,
            },
            {
              to: "http://www.jntua-otpri.ac.in",
              label: "JNTUA - (OTPRI) - Ananthapuramu",
              newTab: true,
              isExternal: true,
            },
          ],
        },
        {
          to: "#",
          label: "AFFILIATED COLLEGES",
          newTab: false,
          children: [
            {
              to: "#",
              label: "Anantapur District",
              newTab: false,
            },
            {
              to: "#",
              label: "Chittoor District",
              newTab: false,
            },
            {
              to: "#",
              label: "Y.S.R District",
              newTab: false,
            },
            {
              to: "#",
              label: "Kurnool District",
              newTab: false,
            },
            {
              to: "#",
              label: "Nellore District",
              newTab: false,
            },
          ],
        },
      ],
    },
  
    {
      label: "Assessment & Accreditation",
      children: [
        {
          to: "/iqac",
          label: "IQAC",
          newTab: false,
          children: [
            {
              to: "/iqac/about",
              label: "About IQAC",
              newTab: false,
            },
            {
              to: "/iqac/functions",
              label: "Functions of IQAC",
              newTab: false,
            },
            {
              to: "/iqac/benefits",
              label: "Benefits of IQAC",
              newTab: false,
            },
            {
              to: "/iqac/important-initiatives",
              label: "Important Initiatives Started at JNTUA",
              newTab: false,
            },
          ],
        },
        {
          to: "",
          label: "NIRF",
          children: [
            {
              to: "/jntua-nirf",
              label: "JNTUA- NIRF",
              newTab: false,
            },
            {
              to: "/nirf-reports",
              label: "NIRF Reports",
              newTab: false,
            },
          ],
        },
        {
          to: "",
          label: "AISHE",
          children: [
            {
              to: "/jntua-aishe",
              label: "JNTUA- AISHE",
              newTab: false,
            },
            {
              to: "/aishe-reports",
              label: "AISHE Reports",
              newTab: false,
            },
          ],
        },
        {
          to: "#",
          label: "NAAC",
          children: [
            {
              to: "/naac/Extended-Profile-Metrics",
              label: "Extended Profile Metrics",
              newTab: false,
              children: [
                {
                  to: "/naac/Criteria-1",
                  label: "Criteria 1",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-2",
                  label: "Criteria 2",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-3",
                  label: "Criteria 3",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-4",
                  label: "Criteria 4",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-5",
                  label: "Criteria 5",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-6",
                  label: "Criteria 6",
                  newTab: false,
                },
                {
                  to: "/naac/Criteria-7",
                  label: "Criteria 7",
                  newTab: false,
                },
                {
                  to: "/naac/Workshop-Seminars",
                  label: "Workshop/Seminars",
                  newTab: false,
                },
              ],
            },
            {
              to: JNTUA_PDF_Links?.Self_Study_Report,
              label: "Self-Study Report (SSR)",
              newTab: false,
            },
          ],
        },
      ],
    },
    {
      label: "for MORE...",
      children: [
        {
          to: "/registered-ph-d-scholars",
          label: "Registered Ph.D. Scholars",
          newTab: false,
        },
        {
          to: "#",
          label: "Events",
          newTab: false,
        },
        {
          to: "/archive",
          label: "Archive",
          newTab: false,
        },
        {
          to: JNTUA_PDF_Links.Publishers_Pdf_File,
          label: "List of suggested books Indian Author Publishers",
          newTab: true,
          pdfLink:true,
        },
        {
          to: "https://cets.apsche.ap.gov.in/APSCHE/APSCHEHome.aspx",
          label: "AP EAPCET 2022",
          newTab: true,
          pdfLink:true,
        },
        {
          to: JNTUA_PDF_Links.MoRTH_Recruitment,
          label: "MoRTH Recruitment",
          newTab: true,
          pdfLink:true,
        },
        {
          to: "https://cets.apsche.ap.gov.in/APPGCET2023/UI/HomePages/Home",
          label: "AP PGCET 2023",
          newTab: true,
          pdfLink:true,
        },
        {
          to: "",
          label: "Download",
          children: [
            {
              to: JNTUA_PDF_Links.Procedure_for_Organizing_Graduation_day,
              label: "Procedure for Organizing Graduation Day",
              newTab: true,
              pdfLink:true,
            },
            {
              to: "/guidelines-for-donation",
              label: "Guidelines for Donation",
              newTab: false,
            },
            {
              to: "/research-development",
              label: "Research & Development",
              newTab: false,
            },
            {
              to: "/syllabus",
              label: "Syllabus",
              newTab: false,
            },
            {
              to: "/annual-reports",
              label: "Annual Reports",
              newTab: false,
            },
            {
              to: "/examination-branch-application-forms",
              label: "Examination Branch Application Forms",
              newTab: false,
            },
            {
              to: "/news-letters",
              label: "News Letters",
              newTab: false,
            },
          ],
        },
        {
          to: "",
          label: "Important Units",
          children: [
            {
              to: "/profile/director-of-skill-development-centre-incubation-centre",
              label: "Skill Development & Incubation Center",
              newTab: false,
            },
            {
              to: "/nss",
              label: "NSS Cell",
              newTab: false,
            },
            {
              to: "/scst",
              label: "SC/ST Cell",
              newTab: false,
            },
            {
              to: "/sports",
              label: "Sports Council",
              newTab: false,
            },
            {
              to: "/arc",
              label: "Anti-Ragging Cell",
              newTab: false,
            },
            {
              to: "/profile/public-relations-office",
              label: "Public Relations Office",
              newTab: false,
            },
          ],
        },
      ],
    },
  ];
  const renderNestedLevels = (data) => {
    return data.map((item, itemIndex) => (
      <SubLevelComp
        item={item}
        renderNestedLevels={renderNestedLevels}
        key={itemIndex}
      />
    ));
  };

  return renderNestedLevels(items);
}

const SubLevelComp = ({ item, renderNestedLevels }) => {
  const location = useLocation();
  const [selected, setselected] = React.useState("");

  const toggle = () => {
    setselected(selected === "" ? "active" : "");
  };

  const hasChidlren = (item) => {
    return Array.isArray(item.children) && item.children.length > 0;
  };

  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };
  const subDomain = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };

  return (
    <li
      className={
        selected === "active"
          ? "menu-item-has-children current-menu-item"
          : "menu-item-has-children"
      }
    >
      <div className="d-flex">
        {item.newTab && item.isSubdomain ? (
          <a href="javascript: void(0)" onClick={() => subDomain(item.to)}>
            {item.label}
          </a>
        ) : item.newTab && item.isExternal ? (
          <a onClick={() => externalLink(item.to)}>{item.label}</a>
        ) : item.newTab && item.pdfLink ? (
          <a href={item.to} target="_blank">
            {item.label}
          </a>
        ) : (
          <Link
            to={item.to}
            className={location.pathname === item.to ? "active-menu" : ""}
          >
            {item.label}
          </Link>
        )}
        {hasChidlren(item) && <span onClick={() => toggle()}></span>}
      </div>
      {selected && (
        <ul
          className={
            selected === "active" ? "sub-menu current-menu" : "sub-menu"
          }
        >
          {hasChidlren(item) && renderNestedLevels(item.children)}
        </ul>
      )}
    </li>
  );
};

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
}) => {
  const location = useLocation();

  
  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <Accordion />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
