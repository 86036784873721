import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../../useWindowSize";
import { Get_JNTUA_PDF_Links } from "../../../utils/fetchData";

const externalLink = (url) => {
  const userConfirmed = window.confirm(
    "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
  );

  if (userConfirmed) {
    window.open(url, "_blank");
  } else {
  }
};
const subDomain = (url) => {
  const userConfirmed = window.confirm(
    "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
  );

  if (userConfirmed) {
    window.open(url, "_blank");
  } else {
  }
};

export const AboutMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();

  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="About" className={"widget-title"}>
          About{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/about">Genesis-JNTUA</Link>
            </li>
            <li>
              <Link to="/vision-mission">Vision & Mission</Link>
            </li>
            <li>
              <Link to="/university-goals">University Goals</Link>
            </li>
            <li>
              <Link to="/our-faculty">Our Faculty</Link>
            </li>
            <li>
              <Link to="#">JNTUA Policies</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/honoris-causa">Honoris Causa</Link>
            </li>
            <li>
              <Link to="/governing-hierarchy">Governing Hierarchy</Link>
            </li>
            <li>
              <Link to="/gallery/univ-gallery">JNTUA Gallery</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/tie-ups-mous">Tie Ups & MOU’s</Link>
            </li>
            <li>
              <Link to="/e-magazines">e-Magazines</Link>
            </li>
            <li>
              <a
                href={
                  "https://www.youtube.com/channel/UCPa6B2sY8Zz13i1mOTyIBvA/videos"
                }
                target="_blank"
                rel="noreferrer"
              >
                75 Years Celebrations of JNTUACEA
              </a>
            </li>
            <li>
              <Link to="/university-song">JNTUA Anthem</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/contact-us">
                <strong>Contact Us</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/contact-us/university-officials">
                    Contact Details of University Officials
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us/examination-branch">
                    Examination Branch
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us/engineering-cell">
                    Engineering Cell
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us/co-ordinators">Co-Ordinators</Link>
                </li>
                <li>
                  <Link to="/convocations">Convocations</Link>
                </li>
                <li>
                  <Link to="/contact-us/sports-council">Sports Council</Link>
                </li>
                <li>
                  <Link to="/contact-us/pro-peshi">PRO / Peshi</Link>
                </li>
                <li>
                  <Link to="/contact-us/administrative-office-staff">
                    Administrative Office Staff
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/location-map">Location Map</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const AdministrationMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Administration" className={"widget-title"}>
          Administration{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/profile/chancellor">Chancellor</Link>
            </li>
            <li>
              <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/profile/rector">Rector</Link>
            </li>
            <li>
              <Link to="/profile/registrar">Registrar</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/executive-council">Executive Council</Link>
            </li>
            <li>
              <Link to="#">University Administration / Directorate’s</Link>
            </li>
            <li>
              <Link to="/former-vice-chancellors">Former Vice Chancellors</Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to="/directorates">
                <strong>Directorates</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/profile/academics-and-planning-dap">
                    Academic & Planning
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-academic-audit">
                    Academic Audit
                  </Link>
                </li>

                <li>
                  <Link to="/profile/admissions-da">Admissions</Link>
                </li>
                <li>
                  <Link to="/profile/director-of-evaluation-de">
                    Director of Evaluation
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-research-and-development">
                    Research and Development
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-industrial-relations-placements">
                    Industrial Relations & Placements
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-industrial-consultancy-services">
                    Industrial Consultancy Services
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-foreign-affairs-alumni-matters">
                    Foreign Affairs & Alumni Matters
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-faculty-development-iqac">
                    Faculty Development
                  </Link>
                </li>
                <li>
                  <Link to="/profile/software-development-centre">
                    Software Development Centre
                  </Link>
                </li>
                <li>
                  <Link to="/profile/sponsored-research">
                    Sponsored Research
                  </Link>
                </li>
                <li>
                  <Link to="/profile/women-empowerment-cell">
                    Women Empowerment Cell
                  </Link>
                </li>
                <li>
                  <Link to="/profile/internal-quality-assurance-cell">
                    Internal Quality Assurance Cell
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-oil-technological-pharmaceutical-research-institute">
                    Oil Technological & Pharmaceutical Research Institute
                  </Link>
                </li>
                <li>
                  <Link to="/profile/school-of-management-studies">
                    School of Management Studies
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const AcademicsMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Academics" className={"widget-title"}>
          Academics
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/methodology">Methodology</Link>
            </li>
            <li>
              <Link to="/admissions">Admission</Link>
            </li>
            <li>
              <Link to="/syllabus">Regulations & Syllabus</Link>
            </li>
            <li>
              <Link to="/programmes-offered">Programmes Offered</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/under-graduate">
                <strong>Under Graudate</strong>
              </Link>
              <ul>
                <li>
                  <a href="/course/1">Bachelor of Technology</a>
                </li>
                <li>
                  <a href="/course/2">Bachelor of Pharmacy</a>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/post-graduate">
                <strong>Post Graduate</strong>
              </Link>
              <ul>
                <li>
                  <a href="/course/5">Master of Technology</a>
                </li>
                <li>
                  <a href="/course/6">Master of Pharmacy</a>
                </li>
                <li>
                  <a href="/course/7">Master of Computer Applications</a>
                </li>
                <li>
                  <a href="/course/8">Master of Bussiness Administration</a>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/phd-courses">Phd Courses</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>EXAMINATIONS</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/profile/director-of-evaluation-de">
                    Director of Evaluation
                  </Link>
                </li>
                <li>
                  <Link to="/controller-of-examination-ug">
                    Controller of Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/addl-controller-of-examinations">
                    Addl. Controller of Examinations
                  </Link>
                </li>
                <li>
                  <Link to="/examination-branch-application-forms">
                    Downloadable Documents
                  </Link>
                </li>
                <li>
                  <a
                    href={"https://jntuaebranchpayment.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Examination Branch Online Applications
                  </a>
                </li>
                <li>
                  <a
                    href={"https://www.icredify.com/degreeverify/jntua"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Degree Verification
                  </a>
                </li>
                <li>
                  <Link to="/category/University-Examinations">
                    Exams Calendars
                  </Link>
                </li>
                <li>
                  <a
                    href={"https://jntuaresults.ac.in/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Results
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>Constituent Colleges</strong>
              </Link>
              <ul>
                <li>
                  <Link
                    onClick={() => externalLink("https://www.jntuacea.ac.in/")}
                  >
                    JNTUA - Ananthapuramu
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => externalLink("https://www.jntuacep.ac.in/")}
                  >
                    JNTUA - Pulivendula
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => externalLink("https://www.jntuacek.ac.in/")}
                  >
                    JNTUA - Kalikiri
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      externalLink("http://www.jntua-otpri.ac.in/")
                    }
                  >
                    JNTUA - (OTPRI) - Ananthapuramu
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>AFFILIATED COLLEGES</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/affiliated-colleges-anantapur">
                    Anantapur District
                  </Link>
                </li>
                <li>
                  <Link to="/affiliated-colleges-chittoor">
                    Chittoor District
                  </Link>
                </li>
                <li>
                  <Link to="/affiliated-colleges-y-s-r">Y.S.R District</Link>
                </li>
                <li>
                  <Link to="/affiliated-colleges-kurnool">
                    Kurnool District
                  </Link>
                </li>
                <li>
                  <Link to="/affiliated-colleges-nellore">
                    Nellore District
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const AccreditationMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);
  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Assessment & Accreditation" className={"widget-title"}>
          Assessment & Accreditation{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/iqac">
                <strong>IQAC</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/iqac/about">About IQAC</Link>
                </li>
                <li>
                  <Link to="/iqac/functions">Functions of IQAC</Link>
                </li>
                <li>
                  <Link to="/iqac/benefits">Benefits of IQAC</Link>
                </li>
                <li>
                  <Link to="/iqac/important-initiatives">
                    Important Initiatives Started at JNTUA
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>NIRF</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/jntua-nirf">JNTUA- NIRF</Link>
                </li>
                <li>
                  <Link to="/nirf-reports">NIRF Reports</Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>AISHE</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/jntua-aishe">JNTUA- AISHE</Link>
                </li>
                <li>
                  <Link to="/aishe-reports">AISHE Reports</Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">
                <strong>NAAC</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/naac/Extended-Profile-Metrics">
                    Extended Profile Metrics
                  </Link>
                  <ul className="ps-3">
                    <li>
                      <Link to="/naac/Criteria-1">Criteria 1</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-2">Criteria 2</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-3">Criteria 3</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-4">Criteria 4</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-5">Criteria 5</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-6">Criteria 6</Link>
                    </li>
                    <li>
                      <Link to="/naac/Criteria-7">Criteria 7</Link>
                    </li>
                    <li>
                      <Link to="/naac/Workshop-Seminars">
                        Workshop/Seminars
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href={JNTUA_PDF_Links?.Self_Study_Report}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Self-Study Report (SSR)
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const ForMoreMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  const [JNTUA_PDF_Links, setJNTUAPdfLinks] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await Get_JNTUA_PDF_Links();
      setJNTUAPdfLinks(data);
    };

    fetchData();
  }, []);
  return (
    <div className={"widget"}>
      <div>
        <h1 title="Assessment & Accreditation" className={"widget-title"}>
          For More
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul className="sub-menu">
            <li>
              <Link to="/registered-ph-d-scholars">
                Registered Ph.D. Scholars
              </Link>
            </li>
            <li>
              <Link to="/category/Events">Events</Link>
            </li>
            <li>
              <Link to="/archive">Archive</Link>
            </li>
            <li>
              <a
                href={JNTUA_PDF_Links.Publishers_Pdf_File}
                target="_blank"
                rel="noreferrer"
              >
                List of suggested books Indian Author Publishers
              </a>
            </li>
            <li>
              <a
                href={"https://cets.apsche.ap.gov.in/APSCHE/APSCHEHome.aspx"}
                target="_blank"
                rel="noreferrer"
              >
                AP EAPCET 2022
              </a>
            </li>
            <li>
              <a
                href={JNTUA_PDF_Links.MoRTH_Recruitment}
                target="_blank"
                rel="noreferrer"
              >
                MoRTH Recruitment
              </a>
            </li>
            <li>
              <a
                href={
                  "https://cets.apsche.ap.gov.in/APPGCET2023/UI/HomePages/Home"
                }
                target="_blank"
                rel="noreferrer"
              >
                AP PGCET 2023
              </a>
            </li>
          </ul>
          <ul className="sub-menu">
            <li>
              <Link to="#">
                <strong>Download</strong>
              </Link>
              <ul>
                <li>
                  <a
                    href={
                      JNTUA_PDF_Links.Procedure_for_Organizing_Graduation_day
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Procedure for Organizing Graduation Day
                  </a>
                </li>
                <li>
                  <Link to="/guidelines-for-donation">
                    Guidelines for Donation
                  </Link>
                </li>
                <li>
                  <Link to="/research-development">Research & Development</Link>
                </li>
                <li>
                  <Link to="/syllabus">Syllabus</Link>
                </li>
                <li>
                  <Link to="/annual-reports">Annual Reports</Link>
                </li>
                <li>
                  <Link to="/examination-branch-application-forms">
                    Examination Branch Application Forms
                  </Link>
                </li>
                <li>
                  <Link to="/news-letters">News Letters</Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="sub-menu">
            <li>
              <Link to="#">
                <strong>Important Units</strong>
              </Link>
              <ul>
                <li>
                  <Link to="/profile/director-of-skill-development-centre-incubation-centre">
                    Skill Development & Incubation Center
                  </Link>
                </li>
                <li>
                  <Link to="/nss">NSS Cell</Link>
                </li>
                <li>
                  <Link to="/scst">SC/ST Cell</Link>
                </li>
                <li>
                  <Link to="/sports">Sports Council</Link>
                </li>
                <li>
                  <Link to="/arc">Anti-Ragging Cell</Link>
                </li>
                <li>
                  <Link to="/profile/public-relations-office">
                    Public Relations Office
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const NotificationMenu = ({ list }) => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Notification Center" className={"widget-title"}>
          Notification Center{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/category/DAA-Notifications">DAA Notifications</Link>
            </li>
            <li>
              <Link to="/category/DAP-Notifications">DAP Notifications</Link>
            </li>
            <li>
              <Link to="/category/FAAM-Notifications">FAAM Notifications</Link>
            </li>
            <li>
              <Link to="/category/Faculty-Development">
                Faculty Development Notifications
              </Link>
            </li>
            <li>
              <Link to="/category/Latest-News">Latest News</Link>
            </li>
            <li>
              <Link to="/category/Live-Scrolling">Scrolling</Link>
            </li>
            <li>
              <Link to="/category/SDIC-Notifications">SDIC Notifications</Link>
            </li>
            <li>
              <Link to="/category/Uncategorized">Uncategorized</Link>
            </li>
            <li>
              <Link to="/category/University-Admissions">
                University Admissions
              </Link>
            </li>
            <li>
              <Link to="/category/Anti-Ragging">
                University Anti Ragging Cell
              </Link>
            </li>
            <li>
              <Link to="/category/Events">University Events</Link>
            </li>
            <li>
              <Link to="/category/University-Examinations">
                University Examinations
              </Link>
            </li>
            <li>
              <Link to="/category/Gallery">University Gallery</Link>
            </li>
            <li>
              <Link to="/category/Industrial-Consultancy-Services">
                University Industrial Consultancy Services
              </Link>
            </li>
            <li>
              <Link to="/category/Industrial-Relations-&-Placements">
                University Industrial Relations & Placements
              </Link>
            </li>
            <li>
              <Link to="/category/NSS-Cell">University NSS Cell</Link>
            </li>
            <li>
              <Link to="/category/Research-&-Development">
                University Research & Development
              </Link>
            </li>
            <li>
              <Link to="/category/University-Sports">University Sports</Link>
            </li>
            <li>
              <Link to="/category/Tenders">University Tenders</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const JNTUKHappeningMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="JNTUA Happenings" className={"widget-title"}>
          JNTUA Happenings{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/gallery/academic-events">Academic Events</Link>
              <ul>
                <li>
                  <Link to="/convocations">Convocations</Link>
                </li>
                <li>
                  <Link to="/gallery/festivals">Festivals</Link>
                </li>
                <li>
                  <Link to="/gallery/university-celebrations">
                    University Celebrations
                  </Link>
                </li>
                <li>
                  <Link to="/gallery/sports-events">Sports Events</Link>
                </li>
                <li>
                  <Link to="/category/Press-Releases">Media/Press</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const DassultSystemsMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();

  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Dassult Systems" className={"widget-title"}>
          Dassult Systems{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/sdic/dassult-systems/intro">
                Introduction to the Project
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/employees">
                OnBoard Working Employees
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/courses-offered">
                Courses Offered
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/lab-infrastructure">
                Lab Infrastructure
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/training">Training</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/sdic/dassult-systems/events">Upcoming Events</Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/registration">Registration</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/sdic/dassult-systems/photo-gallery">
                Photo Gallery
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/video-gallery">
                Video Gallery
              </Link>
            </li>
            <li>
              <Link to="/sdic/dassult-systems/news">News Paper Clippings</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const SCOEMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();

  return (
    <div className={"widget"}>
      <div
        className={`body-overlay ${internalMenuOpen ? "show" : ""}`}
        onClick={() => {
          setInternalMenuOpen(!internalMenuOpen);
        }}
      ></div>
      <div>
        <h1 title="Siemens Centre of Excellence" className={"widget-title"}>
          Siemens Centre of Excellence{" "}
          <span
            className="fad fa-list-ul d-xl-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        <div
          className={`sideMenusWrap ${internalMenuOpen ? "sideMenuOpen" : ""}`}
        >
          <ul>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/equipments">
                List of Equipments
              </Link>
            </li>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/laboratories">
                List of Laboratories
              </Link>
            </li>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/employees">
                Onboard Working Employees
              </Link>
            </li>

            <li>
              <Link to="/sdic/siemens-centre-of-excellence/registration">
                Registration
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/photo-gallery">
                Photo Gallery
              </Link>
            </li>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/video-gallery">
                Video Gallery
              </Link>
            </li>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/media">
                In Media
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/brochures">
                Brochures
              </Link>
            </li>
            <li>
              <Link to="/sdic/siemens-centre-of-excellence/candidates">
                List of Candidates trained
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
